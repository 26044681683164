import React from "react";
import { useIntl } from "react-intl";
import { Link, useParams, useHistory } from "react-router-dom";

import { StyledGrid } from "@opr-finance/component-grid";
import { StyledPageTitle } from "@opr-finance/component-content";
import { ErrorPageStyles, PageTitleStyles } from "@opr-finance/theme-flex-online";
import { Font } from "@opr-finance/component-fonts";
import { StyledLink } from "@opr-finance/component-link-to";

import { E_ErrorTypes, E_Routes } from "../../types/general";
import { messages } from "./messages";

export function ErrorPage() {
    const history = useHistory();
    const { formatMessage: fm } = useIntl();
    let { type } = useParams<{ type: string }>();
    const getTitle = (type) => {
        switch (type) {
            case E_ErrorTypes.FORBIDDEN:
                return messages.forbiddenPageTitle;
            case E_ErrorTypes.NOT_FOUND:
                return messages.notFoundPageTitle;
            default:
                return messages.defaultErrorPageTitle;
        }
    };

    return (
        <StyledGrid styleConfig={{ root: ErrorPageStyles.errorPageRootStyles() }}>
            <StyledPageTitle
                title={fm(getTitle(type))}
                styleConfig={{
                    pageTitleContainer: PageTitleStyles.titleBox,
                    pageTitleText: PageTitleStyles.pageTitle,
                }}
            />
            <StyledGrid styleConfig={{ root: ErrorPageStyles.contentContainer() }}>
                <Font styleConfig={{ root: ErrorPageStyles.content() }}>
                    {fm(messages.pageInfo1)}{" "}
                    <Link to={E_Routes.ROOT} style={{ color: "#5c98d3" }}>
                        {fm(messages.LinkText1)}
                    </Link>{" "}
                    {fm(messages.pageInfo2)}{" "}
                    <StyledLink
                        href={`${process.env.REACT_APP_LOGOUT_REDIRECT}`}
                        styleConfig={{ root: ErrorPageStyles.link() }}
                    >
                        {fm(messages.LinkText2)}
                    </StyledLink>
                    .
                </Font>
            </StyledGrid>
        </StyledGrid>
    );
}
