import { takeEvery, call, put, take } from "redux-saga/effects";

import { errorActions } from "@opr-finance/feature-error";

import { AppActionConstants, appActions } from "../actions/actions";
import { engagementActions } from "@opr-finance/feature-sme-customer";

export function* watchApplicationPageTrigger() {
    yield takeEvery(AppActionConstants.APPLICATION_PAGE_TRIGGER, handleApplicationPageTrigger);
}

export function* handleApplicationPageTrigger() {
    try {
        yield put(engagementActions.engagementTrigger());
        yield put(appActions.applicationPageSuccess());
    } catch (e) {
        yield put(
            errorActions.errorTrigger({
                message: "Application page load failed" + e,
                url: "/error",
            })
        );
    }
}
