import { ActionType, createAction } from "typesafe-actions";
import {
    StrapiCmsActionsConstants,
    T_News,
    T_Notice,
    T_StrapiCmsPayload,
    T_TranslationSuccessPayload,
} from "../types/general";

export const strapiCmsActions = {
    fetchTranslationsTrigger: createAction(
        StrapiCmsActionsConstants.FETCH_TRANSLATIONS_TRIGGER
    )<T_StrapiCmsPayload>(),
    fetchTranslationsSuccess: createAction(
        StrapiCmsActionsConstants.FETCH_TRANSLATIONS_SUCCESS
    )<T_TranslationSuccessPayload>(),
};

export type StrapiCMSAction = ActionType<typeof strapiCmsActions>;
