export function flattenGlobalMessages(nestedMessages, prefix = "global") {
    if (nestedMessages) {
        const { localizations, ...rest } = nestedMessages;
        return Object.keys(rest).reduce((messages, key) => {
            let value = rest[key];
            let prefixedKey = prefix ? `${prefix}.${key}` : key;

            if (typeof value === "string") {
                messages[prefixedKey] = value;
            } else {
                Object.assign(messages, flattenGlobalMessages(value, prefixedKey));
            }

            return messages;
        }, {});
    } else {
        return;
    }
}
export function flattenApplicationMessages(nestedMessages, prefix = "") {
    if (nestedMessages) {
        const { localizations, flexOnlineNews, flexOnlineNotice, ...rest } = nestedMessages;
        return Object.keys(rest).reduce((messages, key) => {
            let value = rest[key];
            let prefixedKey = prefix ? `${prefix}.${key}` : key;

            if (typeof value === "string") {
                messages[prefixedKey] = value;
            } else {
                Object.assign(messages, flattenApplicationMessages(value, prefixedKey));
            }

            return messages;
        }, {});
    } else {
        return;
    }
}
