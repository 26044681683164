import { T_Countries, T_StrapiResponse } from "../types/general";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

type T_ApiError = {
    message: string;
};

export async function getApplicationTranslations(
    locale: T_Countries,
    gwUrl: string
): Promise<T_StrapiResponse | undefined> {
    let response: Promise<T_StrapiResponse>;

    const config = {
        method: "GET",
        headers: {
            "Content-type": "application/json",
        },
    };

    try {
        response = (await fetch(`${gwUrl}/content/flex/online/${locale}`, config)).json();
        return (await response).data.attributes;
    } catch (e: unknown) {
        const err = e as T_ApiError;
        logger.log(err.message);
    }
}

export async function getGlobalTranslations(
    locale: T_Countries,
    gwUrl: string
): Promise<T_StrapiResponse | undefined> {
    let response: Promise<T_StrapiResponse>;

    const config = {
        method: "GET",
        headers: {
            "Content-type": "application/json",
        },
    };

    try {
        response = await (await fetch(`${gwUrl}/content/flex/global/${locale}`, config)).json();
        return (await response).data.attributes;
    } catch (e: unknown) {
        const err = e as T_ApiError;
        logger.log(err.message);
    }
}

// export async function getNews(
//     locale: T_Countries,
//     gwUrl: string
// ): Promise<T_StrapiResponse | undefined> {
//     let response: Promise<T_StrapiResponse>;

//     const config = {
//         method: "GET",
//         headers: {
//             "Content-type": "application/json",
//         },
//     };

//     try {
//         response = await (await fetch(`${gwUrl}/content/opr/news/${locale}`, config)).json();
//         return (await response).data.attributes;
//     } catch (e: unknown) {
//         const err = e as T_ApiError;
//         console.log(err.message);
//     }
// }

// export async function getNotices(
//     locale: T_Countries,
//     gwUrl: string
// ): Promise<T_StrapiResponse | undefined> {
//     let response: Promise<T_StrapiResponse>;

//     const config = {
//         method: "GET",
//         headers: {
//             "Content-type": "application/json",
//         },
//     };

//     try {
//         response = await (await fetch(`${gwUrl}/content/opr/notice/${locale}`, config)).json();
//         return (await response).data.attributes;
//     } catch (e: unknown) {
//         const err = e as T_ApiError;
//         console.log(err.message);
//     }
// }
