import React from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { StyledGrid } from "@opr-finance/component-grid";
import { StyledPageTitle } from "@opr-finance/component-content";
import { LoanPageStyles } from "@opr-finance/theme-flex-online";
import { CurrencyLocale, CurrencyFormat, currency } from "@opr-finance/component-currency";
import { smeDocumentActions } from "@opr-finance/feature-document";
import { T_FormattedInvoice } from "@opr-finance/feature-statements";
import { I_SmeFormattedTransactions } from "@opr-finance/feature-transactions";
import { E_AllowedAccountStates } from "@opr-finance/feature-account";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { AppState, E_Routes } from "../../types/general";
import { LoanPageProps } from "./types";
import { messages } from "./messages";
import { WithdrawButtonMobile } from "../../components/WithdrawButtonMobile/WithdrawButtonMobile";
import { InvoicesBlock } from "../../components/InvoicesBlock/InvoicesBlock";
import { InfoAndContactBlock } from "../../components/InfoAndContactBlock/InfoAndContactBlock";
import { ReportingBlock } from "../../components/ReportingBlock/ReportingBlock";

export function LoanPage(props: LoanPageProps) {
    const dispatch = useDispatch();
    const { formatMessage: fm } = useIntl();

    const logger = new ConsoleLogger({ level: LOG_LEVEL });
    const documentInUse = process.env.REACT_APP_DISPLAY_DOCUMENTS === "1" ? true : false;

    const authenticated = useSelector((state: AppState) => state.session.authenticated);
    const accountState = useSelector((state: AppState) => state.account.accountState);

    const transactionsData: Array<I_SmeFormattedTransactions> = useSelector(
        (state: AppState) => state.transactions.formatted
    );

    const invoicesData: Array<T_FormattedInvoice> = useSelector(
        (state: AppState) => state.invoices.formatted
    );

    const account = useSelector((state: AppState) => state.account.account);
    const { defaultPromissoryNoteId, activeAccountId, token, gwUrl, mockApiCalls } = useSelector(
        (state: AppState) => state.document.config
    );

    const availableBalanceCurrency = currency({
        value: account?.remainingPrincipal,
        locale: CurrencyLocale.Netherlands,
        currency: CurrencyFormat.Netherlands,
        style: "currency",
    });

    const handleClick = () => {
        dispatch(
            smeDocumentActions.smeFetchDocumentTrigger({
                token,
                gwUrl,
                mockApiCalls,
                accountId: activeAccountId,
                documentId: defaultPromissoryNoteId,
            })
        );
    };

    const getPdfClick = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        documentId: string
    ) => {
        event.preventDefault();
        logger.log("documentId", documentId);
        dispatch(
            smeDocumentActions.smeFetchDocumentTrigger({
                documentId,
                accountId: activeAccountId,
                token,
                mockApiCalls,
                gwUrl,
            })
        );
    };

    if (!authenticated || accountState === E_AllowedAccountStates.COLLECTION) {
        return <Redirect to={E_Routes.ROOT} />;
    }

    return (
        <StyledGrid styleConfig={{ root: LoanPageStyles.loanPageRootStyles() }}>
            <StyledPageTitle
                title={fm(messages.pageTitle)}
                styleConfig={{
                    pageTitleContainer: props.styleConfig.titleBox,
                    pageTitleText: props.styleConfig.pageTitle,
                }}
            />
            <InfoAndContactBlock
                documentInUse={documentInUse}
                defaultPromissoryNoteId={defaultPromissoryNoteId}
                handleClick={handleClick}
                availableBalanceCurrency={availableBalanceCurrency}
            />

            <StyledGrid styleConfig={{ root: LoanPageStyles.loanPageSections() }}>
                <WithdrawButtonMobile />
                {/* <TransactionsBlock data={transactionsData} /> */}
                <ReportingBlock />
                <InvoicesBlock data={invoicesData} onClick={getPdfClick} />
            </StyledGrid>
        </StyledGrid>
    );
}
