import React, { useState } from "react";
import { SystemStyleObject } from "@styled-system/css";

import { SectionBox } from "@opr-finance/component-content";
import { Font, Text } from "@opr-finance/component-fonts";
import { LinkTo } from "@opr-finance/component-link-to";
import { Icon } from "@opr-finance/component-icon";
import { ModalDialog, RichContent, Modal } from "@opr-finance/component-modal-dialog";
import { StyledGrid } from "@opr-finance/component-grid";
import { T_News } from "../types/general";

type NewsStyles = {
    newsWrapper?: SystemStyleObject;
    headlines?: SystemStyleObject;
    headlinesModal?: SystemStyleObject;
    newsDate?: SystemStyleObject;
    newsDateModal?: SystemStyleObject;
    news?: SystemStyleObject;
    newsModal?: SystemStyleObject;
    seeMore?: SystemStyleObject;
    modalTitle: SystemStyleObject;
    titleText: SystemStyleObject;
    modalOverlay: SystemStyleObject;
    modalContent: SystemStyleObject;
    modalCloseIcon: SystemStyleObject;
};
export type RecentNewsItemProps = T_News & {
    readMoreLabel: string;
    modalTitle: string;
} & {
    styles?: NewsStyles;
};

export function RecentNewsItem(props: RecentNewsItemProps) {
    const [modalOpen, toggleModalOpen] = useState(false);
    const shorternedNews = props.news && props.news.split(" ").slice(0, 30).join(" ");
    const publishedOnAsDate: string = new Date(props.publishedOn).toDateString();
    if (props.news) {
        if (props.styles) {
            return (
                <StyledGrid styleConfig={{ root: props.styles.newsWrapper }}>
                    <Font styleConfig={{ root: props.styles.headlines }}>{props.newsHeading}</Font>
                    <Font styleConfig={{ root: props.styles.newsDate }}>{publishedOnAsDate}</Font>
                    <Font styleConfig={{ root: props.styles.news }}>{`${shorternedNews} ...`}</Font>
                    <LinkTo
                        variant="light"
                        fontSize="12px"
                        onClick={() => toggleModalOpen(!modalOpen)}>
                        {props.readMoreLabel} <Icon icon={["fas", "angle-double-right"]} />
                    </LinkTo>
                    <Modal
                        modalTitle={props.modalTitle}
                        isOpen={modalOpen}
                        onClick={() => toggleModalOpen(false)}
                        styleConfig={{
                            closeIcon: props.styles.modalCloseIcon,
                            overlay: props.styles.modalOverlay,
                            content: props.styles.modalContent,
                            title: props.styles.modalTitle,
                            titleText: props.styles.titleText,
                        }}>
                        <StyledGrid styleConfig={{ root: props.styles.newsWrapper }}>
                            {" "}
                            <Font styleConfig={{ root: props.styles.headlinesModal }}>
                                {props.newsHeading}
                            </Font>
                            <Font styleConfig={{ root: props.styles.newsDateModal }}>
                                {publishedOnAsDate}
                            </Font>
                            <Font styleConfig={{ root: props.styles.newsModal }}>
                                <RichContent content={props.news} />
                            </Font>
                        </StyledGrid>
                    </Modal>
                </StyledGrid>
            );
        }

        return (
            <SectionBox variant="paddingSizePrimary">
                <Text variant="recentNewsTitle">{props.newsHeading}</Text>
                <Text variant="small">{publishedOnAsDate}</Text>
                <Text variant="body" lineHeight={"1.5"} paddingTop={"4px"}>
                    {props.news}
                </Text>
                <LinkTo variant="light" onClick={() => toggleModalOpen(!modalOpen)}>
                    {props.readMoreLabel} <Icon icon={["fas", "angle-double-right"]} />
                </LinkTo>
                <ModalDialog
                    isOpen={modalOpen}
                    onClick={() => toggleModalOpen(false)}
                    modalTitle={props.newsHeading}
                    dialogVariant={"default"}
                    titleVariant={"default"}>
                    <RichContent content={props.news} createdDate={publishedOnAsDate} />
                </ModalDialog>
            </SectionBox>
        );
    }
    return null;
}
