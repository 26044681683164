import { defineMessages } from "react-intl";
import { LinkTo } from "@opr-finance/component-link-to";

export const messages = defineMessages({
    defaultErrorPageTitle: {
        id: "errorPage.defaultTitle",
    },
    pageInfo1: {
        id: "notFoundPage.loginAgainText",
    },
    pageInfo2: {
        id: "notFoundPage.orLoginToWebText",
    },
    LinkText1: {
        id: "notFoundPage.loginHereLink.displayText",
    },
    Link1: {
        id: "notFoundPage.loginHereLink.url",
    },
    LinkText2: {
        id: "notFoundPage.webPageHereLink.displayText",
    },
    Link2: {
        id: "notFoundPage.webPageHereLink.url",
    },
    notFoundPageTitle: {
        id: "notFoundPage.pageTitle",
    },
    errorMessage: {
        id: "page.notfound.message",
        values: {
            link: (value) => {
                const attrs = value[0].split("|");
                return (
                    <LinkTo variant="basic" href={attrs[1]} fontSize="20px" fontWeight="bold">
                        {attrs[0]}
                    </LinkTo>
                );
            },
        },
    },
    forbiddenPageTitle: {
        id: "errorPage.defaultTitle",
    },
});
