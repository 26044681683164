import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageTitle: {
        id: "userPage.pageTitle",
    },
    TopInfo: {
        id: "userPage.pageTopInfo",
    },
    companyInfoHeading: {
        id: "userPage.companyInfoHeading",
    },
    companyName: {
        id: "userPage.companyNameLabel",
    },
    bankAccount: {
        id: "userPage.accountNumberLabel",
    },
    businessID: {
        id: "userPage.businessIdLabel",
    },
    email: {
        id: "global.emailText",
    },
    emailPlaceholder: {
        id: "userPage.email.placeholder",
    },
    emailRequiredError: {
        id: "userPage.email.errorMessages.required",
    },
    emailWrongError: {
        id: "userPage.email.errorMessages.wrongFormat",
    },
    customerNumber: {
        id: "userPage.customerNumberLabel",
    },
    address: {
        id: "userPage.address.label",
    },
    streetPlaceholder: {
        id: "userPage.addressPlaceholders.street",
    },
    zipPlaceholder: {
        id: "userPage.addressPlaceholders.zip",
    },
    cityPlaceholder: {
        id: "userPage.addressPlaceholders.city",
    },
    addressError: {
        id: "userPage.address.errorMessages.required",
    },

    guarantorInfoHeading: {
        id: "userPage.guarantorInfoHeading",
    },
    guaranteeName: {
        id: "userPage.guarantorNameLabel",
    },
    guaranteeSSN: {
        id: "userPage.guarantorSSNLabel",
    },
    guaranteePhone: {
        id: "global.phoneNumberText",
    },
    guaranteePhonePlaceholder: {
        id: "userPage.PhoneNumber.placeholder",
    },
    guaranteePhoneError: {
        id: "userPage.PhoneNumber.errorMessages.required",
    },
    guaranteePhoneWrongError: {
        id: "userPage.PhoneNumber.errorMessages.wrongFormat",
    },
    editBtnText: {
        id: "userPage.editBtnText",
    },
    saveBtnText: {
        id: "userPage.saveBtnText",
    },
});
