import { T_TopupRules, T_WithdrawalRules } from "@opr-finance/utils";

export const withdrawalRules: T_WithdrawalRules = {
    minWithdrawal: 200,
    overdueDays: 5,
    accountState: ["OPEN", "PENDING"],
    blockedStatus: false,
};

export const topupRules: T_TopupRules = {
    creditLimit: 40000,
    accountState: ["OPEN", "PENDING"],
    overdueDays: 5,
    paidInvoices: 3,
    blockedStatus: false,
};
