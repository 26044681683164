import { put, select, takeEvery } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { getApplicationTranslations, getGlobalTranslations } from "../api/getStrapiContent";
const call: any = Effects.call;

import {
    E_Applications,
    StrapiCmsActionsConstants,
    T_Message,
    T_News,
    T_Notice,
    T_StrapiCmsInitializerConfig,
    T_StrapiCmsState,
    T_StrapiData,
    T_StrapiResponse,
} from "../types/general";
import { strapiCmsActions } from "../actions/strapi-cms.actions";
import { ActionType } from "typesafe-actions";
import { flattenApplicationMessages, flattenGlobalMessages } from "../payload/strapi-cms.payload";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

// TRANSLATIONS SAGA
export function* watchFetchTranslations() {
    yield takeEvery(StrapiCmsActionsConstants.FETCH_TRANSLATIONS_TRIGGER, handleFetchTranslations);
}

export function* handleFetchTranslations(
    action: ActionType<typeof strapiCmsActions.fetchTranslationsTrigger>
) {
    try {
        const { gwUrl, locale } = action.payload;
        const globalTranslations: any = yield call(getGlobalTranslations, locale, gwUrl);
        const applicationTranslations: any = yield call(getApplicationTranslations, locale, gwUrl);
        const flatenGlobalTranslations = flattenGlobalMessages(globalTranslations);
        const flatenApplicationTranslations = flattenApplicationMessages(applicationTranslations);
        const translationData: T_Message = {
            ...flatenGlobalTranslations,
            ...flatenApplicationTranslations,
        };

        const { flexOnlineNews, flexOnlineNotice } = applicationTranslations;

        const strapiData: T_StrapiData = {
            translations: translationData,
            news: flexOnlineNews,
            notice: flexOnlineNotice,
        };

        yield put(
            strapiCmsActions.fetchTranslationsSuccess({
                strapiData: strapiData,
                gwUrl,
                locale,
            })
        );
    } catch (e) {
        logger.log("fetch translations failed", e);
    }
}

// //News SAGA
// export function* watchFetchNews() {
//     yield takeEvery(StrapiCmsActionsConstants.FETCH_NEWS_TRIGGER, handleFetchNews);
// }

// export function* handleFetchNews() {
//     try {
//         const { locale, gwUrl } = yield select((state) => state.strapiCms.config);
//         const data: any = yield call(getNews, locale, gwUrl);
//         const news = data.news.filter(
//             (news: T_News) => news.application === E_Applications[locale]
//         );
//         yield put(strapiCmsActions.fetchNewsSuccess(news));
//     } catch (e) {
//         console.log("fetch news failed", e);
//     }
// }

//Notices SAGA
// export function* watchFetchNotice() {
//     yield takeEvery(StrapiCmsActionsConstants.FETCH_NOTICE_TRIGGER, handleFetchNotice);
// }

// export function* handleFetchNotice() {
//     try {
//         const { locale, gwUrl } = yield select((state) => state.strapiCms.config);
//         const data: any = yield call(getNotices, locale, gwUrl);
//         const notices = data.notice.filter(
//             (notice: T_Notice) => notice.application === E_Applications[locale]
//         );
//         yield put(strapiCmsActions.fetchNoticeSuccess(notices));
//     } catch (e) {
//         console.log("fetch notices failed", e);
//     }
// }
