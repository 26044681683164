import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageTitle: {
        id: "loggedOutPage.pageTitle",
    },
    linkText: {
        id: "loggedOutPage.gotoWebLink.displayText",
    },

    url: {
        id: "loggedOutPage.gotoWebLink.url",
    },
    bodyText: {
        id: "loggedOutPage.returnToWebText",
    },
});
