import { put, take, select, race, all, call } from "redux-saga/effects";

import * as VP from "@opr-finance/api-definitions";
import { loginSessionActions, T_LoginRoles } from "@opr-finance/feature-login-session";
import {
    companyActions,
    engagementActions,
    T_SmeCompanyAccounts,
} from "@opr-finance/feature-sme-customer";
import { translationStatiContentActions } from "@opr-finance/feature-contentful";
import { E_AllowedAccountStates, smeLoanAccountActions } from "@opr-finance/feature-account";
import { errorActions } from "@opr-finance/feature-error";
import { smeDocumentActions } from "@opr-finance/feature-document";
import { E_CurrencyCountry } from "@opr-finance/component-currency";
import { invoicesActions } from "@opr-finance/feature-statements";
import { smeTransactionsActions } from "@opr-finance/feature-transactions";
import { strapiCmsActions, T_StrapiCmsState } from "@opr-finance/feature-strapi-cms";
import { loaderActions } from "@opr-finance/feature-loader";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { AppState, E_Routes, StrapiLocale } from "../types/general";
import history from "../utils/history";
import { getGwProps } from "@opr-finance/utils/src/getGwProps";
import { reportingActions } from "@opr-finance/feature-reporting";
import { T_GatewayProps } from "@opr-finance/utils/src/types/general";

const gw: T_GatewayProps = getGwProps();

export function* loginSessionFeatureInitializer() {
    const logger = new ConsoleLogger({ level: LOG_LEVEL });
    try {
        const cid: string = process.env.REACT_APP_CLIENT_ID as string;
        const lang: string = process.env.REACT_APP_LOCALE as string;
        const verifyUrl: string = `${gw.baseUrl}/jwt/${cid}/verify`;
        const expiredUrl: string = "/expired";
        const errorUrl: string = "/error";
        const endSessionApiUrl: string = `${gw.baseUrl}/v2/auth/logout/${cid}`;
        const getSessionInfoUrl = `${gw.baseUrl}/v2/auth/sessioninfo/${cid}`;

        yield put(
            loginSessionActions.loginSessionInitializer({
                authUrl: gw.fullAuthUrl,
                cid,
                lang,
                verifyUrl,
                expiredUrl,
                errorUrl,
                endSessionApiUrl,
                getSessionInfoUrl,
            })
        );
    } catch (e) {
        logger.log("fetch login page trigger failed");
    }
}

export function* getTranslations() {
    const messages = yield select((state: AppState) => state.translation.messages);
    try {
        if (!messages) {
            yield put(
                translationStatiContentActions.fetchTranslationStaticContentTrigger({
                    clientParams: {
                        space: process.env.REACT_APP_CONTENTFUL_SPACE as string,
                        accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN as string,
                    },
                    contentParams: {
                        content_type: "staticContent",
                        "fields.slug[in]":
                            "flex-online-netherlands-user-page,flex-online-netherlands-topup-page,flex-online-netherlands-error-page,flex-online-netherlands-start-page,flex-online-netherlands-footer,flex-online-netherlands-header,flex-online-netherlands-account-list-page,flex-online-netherlands-contact-page,flex-online-netherlands-expired-page,flex-online-netherlands-front-page,flex-online-netherlands-logout-page,flex-online-netherlands-no-loan-page,flex-online-netherlands-loan-page,flex-online-netherlands-eligible-to-topup-page,flex-online-netherlands-forbidden-page,flex-online-netherlands-not-found-page",
                    },
                })
            );

            yield take(translationStatiContentActions.fetchTranslationStaticContentSuccess);
        }
    } catch (e) {
        yield put(
            errorActions.errorTrigger({
                message: "Loading translations failed" + e,
                url: E_Routes.ERROR,
            })
        );
    }
}
export function* getStrapiCms() {
    const strapiCms: T_StrapiCmsState = yield select((state: AppState) => state.strapiCms);
    if (!strapiCms.messages) {
        try {
            yield put(loaderActions.loaderTrigger({ loading: true }));
            yield put(
                strapiCmsActions.fetchTranslationsTrigger({
                    locale: StrapiLocale.NETHERLANDS,
                    gwUrl: gw.baseUrl,
                })
            );

            yield take(strapiCmsActions.fetchTranslationsSuccess);
            yield put(loaderActions.loaderTrigger({ loading: false }));
        } catch (e) {
            yield put(
                errorActions.errorTrigger({
                    message: "Loading translations failed" + e,
                    url: E_Routes.ERROR,
                })
            );
        }
    }
}

export function* saveLoginSession() {
    const sessionToken = yield select((state: AppState) => state.session.token);
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role") as T_LoginRoles;
    const ssn = localStorage.getItem("reference") as string;

    if (!sessionToken && token) {
        const authenticated = true;
        yield put(
            loginSessionActions.loginSessionTokenSuccess({ token, authenticated, role, ssn })
        );
        yield put(loginSessionActions.loginSessionVerify());
        yield put(
            engagementActions.engagementInitializer({
                mock: gw.mock,
                gwUrl: gw.fullApiUrl,
                role,
                token,
                reference: ssn,
            })
        );
    } else if (!sessionToken && !token) {
        window.location.href = E_Routes.ROOT;
    } else {
        yield put(engagementActions.engagementTrigger());
    }
}

export function* setAccountConfig() {
    const accountConfigUrl = yield select((state: AppState) => state.account.config.gwUrl);
    const token = yield select((state: AppState) => state.session.token);
    if (!accountConfigUrl) {
        yield put(
            smeLoanAccountActions.accountInitializer({
                mockApiCalls: gw.mock,
                gwUrl: gw.fullApiUrl,
                token,
                errorUrl: E_Routes.ERROR,
                noAuth: E_Routes.EXPIRED,
                noLoanUrl: E_Routes.NO_LOAN,
            })
        );
    }
}
export function* setInvoiceConfig() {
    const activeAccountId = (yield select(
        (state: AppState) => state.account.activeAccountId
    )) as string;
    const invoiceConfigUrl = yield select((state: AppState) => state.invoices.config.gwUrl);
    const token = yield select((state: AppState) => state.session.token);

    if (!invoiceConfigUrl) {
        yield put(
            invoicesActions.fetchInvoicesInitializer({
                mockApiCalls: gw.mock,
                gwUrl: gw.fullApiUrl,
                token,
                activeAccountId,
                currencyCountry: E_CurrencyCountry.Netherlands,
            })
        );
    }
}

export function* setTransactionsConfig() {
    const activeAccountId = (yield select(
        (state: AppState) => state.account.activeAccountId
    )) as string;
    const tranationsConfigUrl = yield select((state: AppState) => state.transactions.config.gwUrl);
    const token = yield select((state: AppState) => state.session.token);

    if (!tranationsConfigUrl) {
        yield put(
            smeTransactionsActions.smeFetchTransactionsInitializer({
                mockApiCalls: gw.mock,
                gwUrl: gw.fullApiUrl,
                token,
                accountId: activeAccountId,
                size: 10,
                shownInStatement: true,
                excludeTransactionTypes:
                    "ReservedTrancheTransaction,PayStatementRoundingTransaction,PayStatementInterestTransaction,PayStatementTrancheStartupFeeTransaction,PayStatementAmortizationTransaction,PayStatementLatePaymentInterestTransaction,OverPaymentReducePrincipalTransaction",
                country: "Netherlands",
            })
        );
    }
}
export function* setReportingConfig() {
    const { accountNumber } = yield select((state: AppState) => state.account.account);
    const reportingConfigUrl = yield select((state: AppState) => state.reporting.config.gwUrl);
    const { cid } = yield select((state: AppState) => state.session.config);
    const token = yield select((state: AppState) => state.session.token);

    if (!reportingConfigUrl) {
        yield put(
            reportingActions.reportingInitializer({
                mockApiCalls: gw.mock,
                gwUrl: gw.baseUrl,
                token,
                accountNumber,
                cid,
                country: "Netherlands",
            })
        );
    }
}

export function* getCompanyData(smeId: string) {
    const companyInfo = yield select((state: AppState) => state.customer.companyInfo);
    if (!(companyInfo.info && companyInfo.accounts && companyInfo.boardmembers) && smeId) {
        yield put(companyActions.getCompanyInfoTrigger({ smeId }));
        yield put(companyActions.getCompanyAccountsTrigger({ smeId }));
        yield put(companyActions.getCompanyBoardMembersTrigger({ smeId }));
        // yield take(companyActions.getCompanyInfoSuccess);
        // yield take(companyActions.getCompanyBoardMembersSuccess);
        // yield take(companyActions.getCompanyAccountsSuccess);

        const [companyDataSuccess, companyDataError] = yield race([
            all([
                take(companyActions.getCompanyInfoSuccess),
                take(companyActions.getCompanyAccountsSuccess),
                take(companyActions.getCompanyBoardMembersSuccess),
            ]),
            take(companyActions.getCompanyInfoError),
            take(companyActions.getCompanyAccountsError),
            take(companyActions.getCompanyBoardMembersError),
        ]);

        if (companyDataError) {
            console.log("Common Saga Race failed");
            throw new Error("Get company Data failed");
        }
    }
}

export function* getInvoiceData() {
    yield put(invoicesActions.fetchInvoicesTrigger());
    yield take(invoicesActions.fetchInvoicesSuccess);
}

export function* getTransactionsData() {
    yield put(smeTransactionsActions.smeFetchTransactionsTrigger({}));
    yield take(smeTransactionsActions.smeFetchTransactionsSuccess);
}

export function* getAccountData() {
    const smeId = yield select((state: AppState) => state.customer.engagement.activeSmeId);
    yield call(getCompanyData, smeId);

    const accounts: T_SmeCompanyAccounts = yield select(
        (state: AppState) => state.customer.companyInfo.accounts?.accounts
    );

    let activeAccounts: T_SmeCompanyAccounts =
        accounts &&
        accounts.filter(
            (account) =>
                account?.state === E_AllowedAccountStates.OPEN ||
                account?.state === E_AllowedAccountStates.PENDING ||
                account?.state === E_AllowedAccountStates.COLLECTION
        );

    if (activeAccounts && activeAccounts.length > 1) {
        activeAccounts = activeAccounts.filter((account) => account.state === "OPEN");
    } else if (activeAccounts && activeAccounts.length === 0) {
        history.push(E_Routes.NO_LOAN);
    }

    if (activeAccounts && activeAccounts.length === 1) {
        yield put(
            smeLoanAccountActions.accountSetAccountId({
                activeAccountId: activeAccounts[0].id || "",
            })
        );

        yield put(
            smeLoanAccountActions.fetchAccountTrigger({
                activeAccountId: activeAccounts[0].id || "",
            })
        );

        yield take(smeLoanAccountActions.fetchAccountSuccess);

        const documents: Array<VP.components["schemas"]["DocumentV8"]> = yield select(
            (state: AppState) => state.account.account?.documents
        );

        const { token, gwUrl, mockApiCalls } = yield select(
            (state: AppState) => state.account.config
        );

        yield put(
            smeDocumentActions.smeFetchPromissoryNoteIdInitializer({
                token,
                gwUrl: `${gwUrl}/file`,
                mockApiCalls,
                activeAccountId: activeAccounts[0].id || "",
                defaultPromissoryNoteId: documents[0]?.id || "",
            })
        );
    }
}

export function* checkSession() {
    const sessionToken = yield select((state: AppState) => state.session.token);
    const token = localStorage.getItem("token");

    if (!token && !sessionToken) {
        window.location.href = E_Routes.ROOT;
    }
}
