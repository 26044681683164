import React from "react";
import { useIntl } from "react-intl";

import { Font } from "@opr-finance/component-fonts";
import { StyledGrid } from "@opr-finance/component-grid";
import { FrontPageStyles } from "@opr-finance/theme-flex-online";
import { Image } from "@opr-finance/component-image";

import euroImage from "../../images/yritysluotto-flex-joustoluotto-nosta.svg";
import { useHistory } from "react-router-dom";
import { E_Routes } from "../../types/general";
import { messages } from "../../pages/LoanPage/messages";

export function WithdrawButtonMobile() {
    const { formatMessage: fm } = useIntl();
    const history = useHistory();
    return (
        <StyledGrid
            onClick={() => history.push(E_Routes.FRONT)}
            styleConfig={{
                root: FrontPageStyles.nostoContainer(),
            }}
        >
            <Image
                imageAlt="euro sign"
                imageSrc={euroImage}
                styleConfig={{
                    root: FrontPageStyles.nostoImage(),
                }}
            ></Image>{" "}
            <Font
                styleConfig={{
                    root: FrontPageStyles.nostoText(),
                }}
            >
                {fm(messages.makeWithdrawalBtnText)}
            </Font>
        </StyledGrid>
    );
}
