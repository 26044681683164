// Package imports
import React, { useState } from "react";
import { Route, Switch, useHistory, useLocation, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

// @OPR Imports
import { useWindowSize } from "@opr-finance/utils";
import { iconLibrary } from "@opr-finance/feature-icon-library";
import { StyledLayout } from "@opr-finance/layout-flex-online";
import {
    ButtonStyles,
    HeaderStyles,
    FontsStyles,
    LayoutStyles,
    PageTitleStyles,
    FrontPageStyles,
    LogoutPageStyles,
    ModalStyles,
} from "@opr-finance/theme-flex-online";
import { StyledFooter, StyledFooterContent } from "@opr-finance/component-footer/src/StyledFooter";
import { StyledButton } from "@opr-finance/component-button";
import { Logo } from "@opr-finance/component-logo";
import { MobileNavigation, MorePageLink } from "@opr-finance/component-navigation";
import { Icon } from "@opr-finance/component-icon";
import { Font } from "@opr-finance/component-fonts";
import { AppInitializer, PageInitializer } from "@opr-finance/feature-initializer";
import { AppLoader } from "@opr-finance/feature-loader";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

// File Imports
import "./index.css";
import logo from "./images/yritysluotto-flex-logo.svg";
import { Header, MobileNavItems } from "./components/Header";
import { FrontPage } from "./pages/Frontpage/FrontPage";
import { LoanPage } from "./pages/LoanPage/LoanPage";
import { LogoutPage } from "./pages/LogoutPage/LogoutPage";
import { ErrorPage } from "./pages/ErrorPage/ErrorPage";
import { NoLoanPage } from "./pages/NoLoanPage/NoLoanPage";
import { ExpiredPage } from "./pages/ExpiredPage/ExpiredPage";
import { StartPage } from "./pages/StartPage/StartPage";
import { LoginPage } from "./pages/LoginPage";
import { UserPage } from "./pages/UserPage/UserPage";
import { ContactPage } from "./pages/ContactPage/ContactPage";
import { TopupPage } from "./pages/TopupPage/TopupPage";
import { AccountListPage } from "./pages/AccountListPage/AccountListPage";
import { AppState, E_Routes, E_Page_Ids } from "./types/general";
import { AppActionConstants } from "./actions/actions";
import { messages, footerMessages } from "./messages";
import { getGwProps } from "@opr-finance/utils/src/getGwProps";
import { T_GatewayProps } from "@opr-finance/utils/src/types/general";

iconLibrary.initFlexOnline();

const gw: T_GatewayProps = getGwProps();

const nlMarketingPage = process.env.REACT_APP_MARKETING_PAGE_URL;
const isRescoringAllowed = (process.env.REACT_APP_IS_RESCORING_ALLOWED as string) === "1";

const App: React.FC = () => {
    const [isMorePageVisible, setIsMorePageVisible] = useState(false);
    const authenticated = useSelector((state: AppState) => state.session.authenticated);
    const isLoading = useSelector((state: AppState) => state.loader.loading);

    const history = useHistory();
    const location = useLocation();
    const { width } = useWindowSize();
    const { formatMessage: fm } = useIntl();

    const isPathMatched = (path: string) => location.pathname === path;

    const logger = new ConsoleLogger({ level: LOG_LEVEL });

    if (isLoading) {
        return (
            <AppInitializer
                actionsRequired={[AppActionConstants.APPLICATION_SUCCESS]}
                initializerAction={AppActionConstants.APPLICATION_TRIGGER}>
                <AppLoader />
            </AppInitializer>
        );
    }

    let loginButtonText = "Login";
    if (isPathMatched(E_Routes.NO_LOAN)) {
        loginButtonText = fm(messages.logout);
    } else if (
        isPathMatched(E_Routes.LOGOUT) ||
        isPathMatched(E_Routes.EXPIRED) ||
        isPathMatched(E_Routes.ERROR) ||
        isPathMatched("/error/forbidden")
    ) {
        loginButtonText = fm(messages.logIn);
    } else if (authenticated) {
        loginButtonText = fm(messages.logout);
    } else {
        loginButtonText = fm(messages.logout);
    }

    const noNavPages = [
        E_Routes.ROOT,
        E_Routes.CHOOSE_ACCOUNT,
        E_Routes.NO_LOAN,
        E_Routes.EXPIRED,
        E_Routes.ERROR,
        E_Routes.LOGOUT,
    ].some(
        (path) =>
            path === window.location.pathname || window.location.pathname === "/error/forbidden"
    );

    const morePageLinks = [
        <MorePageLink
            key="mobile-item0"
            onClick={() => {
                history.push(`${E_Routes.USER}`);
                setIsMorePageVisible(false);
            }}>
            <Icon icon={["fas", "user"]} size="lg" />
            <Font styleConfig={{ root: HeaderStyles.mobileNavMoreItemText }}>
                {fm(messages.mobileNavContact)}
            </Font>
        </MorePageLink>,
        <MorePageLink
            key="mobile-item1"
            onClick={() => {
                history.push(`${E_Routes.CONTACT}`);
                setIsMorePageVisible(false);
            }}>
            <Icon icon={["fas", "comment-alt-dots"]} size="lg" />
            <Font styleConfig={{ root: HeaderStyles.mobileNavMoreItemText }}>
                {fm(messages.mobileNavCustomerService)}
            </Font>
        </MorePageLink>,
    ];

    return (
        <StyledLayout
            header={
                <Header
                    navigationVisible={!noNavPages && width >= 786}
                    isTopUpVisible={isRescoringAllowed}
                    logo={
                        <Logo
                            logoSrc={logo}
                            width={[113, 130, 165]}
                            onClick={() => {
                                if (authenticated) {
                                    history.push("/front");
                                } else {
                                    window.location.href = `${nlMarketingPage}`;
                                }
                            }}></Logo>
                    }
                    button={
                        !isPathMatched(E_Routes.ROOT) ? (
                            <StyledButton
                                onClick={() => {
                                    if (authenticated) {
                                        window.location.href = "/logout";
                                    } else {
                                        window.location.href = gw.fullAuthUrl;
                                    }
                                }}
                                styleConfig={{ root: ButtonStyles.buttonStyles() }}>
                                {loginButtonText}
                            </StyledButton>
                        ) : null
                    }
                    authenticated={authenticated}
                />
            }
            footer={
                <StyledFooter
                    columns={StyledFooterContent({
                        styleConfig: {
                            fontFooterText: FontsStyles.fontFooterText(),
                            fontFooterTitle: FontsStyles.fontFooterTitle(),
                        },
                        inputConfig: {
                            messages: {
                                headingLinks: footerMessages.headingLinks,
                                registrationDocumentLinkText:
                                    footerMessages.registrationDocumentLinkText,
                                cookiesLinkText: footerMessages.cookiesLinkText,
                                headingCustomerService: footerMessages.headingCustomerService,
                                openingHours: footerMessages.openingHours,
                                phoneNumber: footerMessages.phoneNumber,
                                emailTextLink: footerMessages.emailTextLink,
                                emailText: footerMessages.emailText,
                                FAQLinkText: footerMessages.FAQLinkText,
                                headingContactAddress: footerMessages.headingContactAddress,
                                companyName: footerMessages.companyName,
                                companyAddress: footerMessages.companyAddress,
                                companyZip: footerMessages.companyZip,
                                businessID: footerMessages.businessID,
                            },
                        },
                    })}
                />
            }
            styleConfig={{
                rootGrid: LayoutStyles.rootGrid(),
                fullWidthGrid: LayoutStyles.fullWidthGrid(),
                mainContentGrid: LayoutStyles.mainContentGrid(),
                bodyBackgroundColor: "#f1faff",
            }}>
            <Switch>
                <Route
                    path={E_Routes.ROOT}
                    exact
                    render={() => {
                        return (
                            <PageInitializer
                                id={E_Page_Ids.START}
                                successfulActions={[AppActionConstants.START_PAGE_SUCCESS]}
                                pageInitAction={AppActionConstants.START_PAGE_TRIGGER}>
                                <StartPage
                                    styleConfig={{
                                        titleBox: PageTitleStyles.titleBox(),
                                        pageTitle: PageTitleStyles.pageTitle(),
                                        loginButton: ButtonStyles.greenButtonStyles({
                                            marginTop: "10px",
                                        }),
                                        applyButton: ButtonStyles.greenButtonStyles({
                                            marginTop: ["10px", "60px"],
                                        }),
                                        buttonText: ButtonStyles.buttonFontStyles(),
                                    }}
                                />
                            </PageInitializer>
                        );
                    }}
                />
                <Route
                    path={E_Routes.LOGIN}
                    render={() => {
                        return (
                            <PageInitializer
                                id={E_Page_Ids.LOGIN}
                                successfulActions={[AppActionConstants.LOGIN_PAGE_SUCCESS]}
                                pageInitAction={AppActionConstants.LOGIN_PAGE_TRIGGER}>
                                <LoginPage />
                            </PageInitializer>
                        );
                    }}
                />

                <Route
                    path={E_Routes.ERROR}
                    render={() => {
                        return <ErrorPage />;
                    }}
                />
                <Route
                    path={E_Routes.ACCOUNTS}
                    render={() => (
                        <PageInitializer
                            id={E_Page_Ids.CHOOSE_ACCOUNT}
                            successfulActions={[AppActionConstants.CHOOSE_ACCOUNT_PAGE_SUCCESS]}
                            pageInitAction={AppActionConstants.CHOOSE_ACCOUNT_PAGE_TRIGGER}>
                            <AccountListPage
                                styleConfig={{
                                    titleBox: PageTitleStyles.titleBox(),
                                    pageTitle: PageTitleStyles.pageTitle(),
                                    textStyle: {
                                        boldedText: FontsStyles.fontBoldedText(),
                                        contentText: FontsStyles.fontContentText(),
                                        boxTitle: FontsStyles.fontBoxTitle(),
                                    },
                                }}
                            />
                        </PageInitializer>
                    )}
                />
                <Route
                    path={E_Routes.FRONT}
                    render={() => {
                        return (
                            <PageInitializer
                                id={E_Page_Ids.FRONT}
                                successfulActions={[AppActionConstants.FRONT_PAGE_SUCCESS]}
                                pageInitAction={AppActionConstants.FRONT_PAGE_TRIGGER}>
                                <FrontPage
                                    styleConfig={{
                                        titleBox: PageTitleStyles.titleBox(),
                                        pageTitle: PageTitleStyles.pageTitle(),
                                        mainContentContainer:
                                            FrontPageStyles.mainContentContainer(),
                                        loanInfoContainer: FrontPageStyles.loanInfoContainer(),
                                        newsContainer: FrontPageStyles.newsContainer(),
                                        creditRaiseContainer:
                                            FrontPageStyles.creditRaiseContainer(),
                                        nostoContainer: FrontPageStyles.nostoContainer(),
                                        nostoImage: FrontPageStyles.nostoImage(),
                                        nostoText: FrontPageStyles.nostoText(),
                                    }}
                                />
                            </PageInitializer>
                        );
                    }}
                />
                <Route
                    path={E_Routes.LOAN}
                    render={() => {
                        return (
                            <PageInitializer
                                id={E_Page_Ids.LOAN}
                                successfulActions={[AppActionConstants.LOAN_PAGE_SUCCESS]}
                                pageInitAction={AppActionConstants.LOAN_PAGE_TRIGGER}>
                                <LoanPage
                                    styleConfig={{
                                        titleBox: PageTitleStyles.titleBox(),
                                        pageTitle: PageTitleStyles.pageTitle(),
                                        textStyle: {
                                            boldedText: FontsStyles.fontBoldedText(),
                                            contentText: FontsStyles.fontContentText(),
                                            boxTitle: FontsStyles.fontBoxTitle(),
                                            amountText: FontsStyles.fontAmountHeading(),
                                            linkText: {},
                                        },
                                    }}
                                />
                            </PageInitializer>
                        );
                    }}
                />
                {isRescoringAllowed && (
                    <Route
                        path={E_Routes.TOPUP}
                        render={() => {
                            return (
                                <PageInitializer
                                    id={E_Page_Ids.TOPUP}
                                    successfulActions={[AppActionConstants.TOPUP_PAGE_SUCCESS]}
                                    pageInitAction={AppActionConstants.TOPUP_PAGE_TRIGGER}>
                                    <TopupPage
                                        styleConfig={{
                                            titleBox: PageTitleStyles.titleBox(),
                                            pageTitle: PageTitleStyles.pageTitle(),
                                            modalCloseIcon: ModalStyles.modalCloseIcon(),
                                            modalOverlay: ModalStyles.modalOverlay(),
                                            modalContent: ModalStyles.modalContent(),
                                            modalTitle: ModalStyles.modalTitle(),
                                            titleText: ModalStyles.titleText(),
                                        }}
                                    />
                                </PageInitializer>
                            );
                        }}
                    />
                )}
                <Route
                    path={E_Routes.USER}
                    render={() => {
                        return (
                            <PageInitializer
                                id={E_Page_Ids.USER}
                                successfulActions={[AppActionConstants.USER_PAGE_SUCCESS]}
                                pageInitAction={AppActionConstants.USER_PAGE_TRIGGER}>
                                <UserPage
                                    styleConfig={{
                                        titleBox: PageTitleStyles.titleBox(),
                                        pageTitle: PageTitleStyles.pageTitle(),
                                    }}
                                />
                            </PageInitializer>
                        );
                    }}
                />
                <Route
                    path={E_Routes.CONTACT}
                    render={() => (
                        <PageInitializer
                            id={E_Page_Ids.CONTACT}
                            successfulActions={[AppActionConstants.CONTACT_PAGE_SUCCESS]}
                            pageInitAction={AppActionConstants.CONTACT_PAGE_TRIGGER}>
                            <ContactPage
                                styleConfig={{
                                    titleBox: PageTitleStyles.titleBox(),
                                    pageTitle: PageTitleStyles.pageTitle(),
                                    textStyle: {
                                        boldedText: FontsStyles.fontBoldedText(),
                                        contentText: FontsStyles.fontContentText(),
                                        boxTitle: FontsStyles.fontBoxTitle(),
                                        linkText: {},
                                    },
                                }}
                            />
                        </PageInitializer>
                    )}
                />

                <Route
                    path={E_Routes.LOGOUT}
                    render={() => {
                        return (
                            <PageInitializer
                                id={E_Page_Ids.LOGOUT}
                                successfulActions={[AppActionConstants.LOGOUT_PAGE_SUCCESS]}
                                pageInitAction={AppActionConstants.LOGOUT_PAGE_TRIGGER}>
                                <LogoutPage
                                    styleConfig={{
                                        rootStyles: LogoutPageStyles.logoutPageRootStyles(),
                                        titleBox: PageTitleStyles.titleBox(),
                                        pageTitle: PageTitleStyles.pageTitle(),
                                        pageContent: LogoutPageStyles.pageContent(),
                                        content: LogoutPageStyles.content(),
                                        link: LogoutPageStyles.link(),
                                    }}
                                />
                            </PageInitializer>
                        );
                    }}
                />

                <Route
                    path={E_Routes.NO_LOAN}
                    render={() => {
                        return (
                            <NoLoanPage
                                styleConfig={{
                                    titleBox: PageTitleStyles.titleBox(),
                                    pageTitle: PageTitleStyles.pageTitle(),
                                    textStyle: {
                                        boldedText: FontsStyles.fontBoldedText(),
                                        contentText: FontsStyles.fontContentText(),
                                        boxTitle: FontsStyles.fontBoxTitle(),
                                        linkText: {},
                                    },
                                }}
                            />
                        );
                    }}
                />
                <Route
                    path={E_Routes.EXPIRED}
                    render={() => {
                        return (
                            <PageInitializer
                                id={E_Page_Ids.EXPIRED}
                                successfulActions={[AppActionConstants.EXPIRED_PAGE_SUCCESS]}
                                pageInitAction={AppActionConstants.EXPIRED_PAGE_TRIGGER}>
                                <ExpiredPage
                                    styleConfig={{
                                        titleBox: PageTitleStyles.titleBox(),
                                        pageTitle: PageTitleStyles.pageTitle(),
                                    }}
                                />
                            </PageInitializer>
                        );
                    }}
                />
                <Route path={E_Routes.ALL_OTHERS}>
                    <Redirect to="/error/pagenotfound" />
                </Route>
            </Switch>
            {width < 786 && !noNavPages && authenticated && (
                <MobileNavigation
                    isVisible={!noNavPages && authenticated}
                    isMorePageVisible={isMorePageVisible}
                    onCloseClick={() => setIsMorePageVisible(false)}
                    morePageTitle={fm(messages.mobileMore)}
                    morePageLinksTitle={"My contact information and customer service"}
                    morePageLinks={morePageLinks}
                    morePageHeader={
                        <Header
                            navigationVisible={false}
                            isTopUpVisible={isRescoringAllowed}
                            logo={
                                <Logo
                                    logoSrc={logo}
                                    width={[113, 165]}
                                    onClick={() => {
                                        if (authenticated) {
                                            window.location.href = "/front";
                                        } else {
                                            window.location.href = "/";
                                        }
                                    }}
                                />
                            }
                            button={
                                !isPathMatched(E_Routes.ROOT) ? (
                                    <StyledButton
                                        onClick={() => {
                                            if (authenticated) {
                                                window.location.href = "/logout";
                                            } else {
                                                window.location.href = gw.fullAuthUrl;
                                            }
                                        }}
                                        styleConfig={{ root: ButtonStyles.buttonStyles() }}>
                                        {loginButtonText}
                                    </StyledButton>
                                ) : null
                            }
                            authenticated={authenticated}
                        />
                    }
                    morePageAfter={""}
                    icons={[
                        <MobileNavItems
                            key="mobileNavItem-0"
                            isMorePageVisible={isMorePageVisible}
                            setIsMorePageVisible={setIsMorePageVisible}
                            isTopUpVisible={isRescoringAllowed}></MobileNavItems>,
                    ]}
                />
            )}
        </StyledLayout>
    );
};

export default App;
