import { defineMessages } from "react-intl";

export const messages = defineMessages({
    expiredPageTitle: {
        id: "expiredPage.pageTitle",
    },
    expiredPageHeadingBox: {
        id: "expiredPage.heading",
    },
    expiredPageText: {
        id: "expiredPage.bodyText",
    },
    expiredPageLinkText: {
        id: "expiredPage.loginLink.displayText",
    },
    expiredPageLinkLink: {
        id: "expiredPage.loginLink.displayUrl",
    },
});
