import { defineMessages } from "react-intl";

export const messages = defineMessages({
    frontPage: {
        id: "header.frontPage",
    },
    loanPage: {
        id: "header.loanInformation",
    },
    topUpPage: {
        id: "header.topup",
    },
    userInfo: {
        id: "header.ownInfo",
    },
    contactInfo: {
        id: "header.customerService",
    },
    logout: {
        id: "header.logoutText",
    },
    logIn: {
        id: "header.loginText",
    },
    mobileTopUp: {
        id: "header.customerService",
    },
    mobileMore: {
        id: "header.mobileNavMore",
    },
    mobileLoanInfo: {
        id: "header.loanInformation",
    },
    mobileNavContact: {
        id: "header.ownInfo",
    },
    mobileNavCustomerService: {
        id: "header.customerService",
    },
});

export const footerMessages = defineMessages({
    headingLinks: {
        id: "footer.usefulLinksHeading",
    },
    headingCustomerService: {
        id: "footer.customerServiceHeading",
    },
    headingContactAddress: {
        id: "footer.contactInfoHeading",
    },
    registrationDocumentLinkText: {
        id: "footer.contactInfoHeading",
    },
    cookiesLinkText: {
        id: "footer.cookiesLink.displayText",
    },
    cookiesLink: {
        id: "footer.cookiesLink.url",
    },
    openingHours: {
        id: "footer.openingHoursText",
    },
    phoneNumberText: {
        id: "global.phoneNumberText",
    },
    phoneNumber: {
        id: "global.phoneNumber",
    },
    emailTextLink: {
        id: "footer.emailLink.url",
    },
    emailText: {
        id: "footer.emailLink.displayText",
    },
    FAQLinkText: {
        id: "footer.faqLink.displayText",
    },
    FAQLink: {
        id: "footer.faqLink.url",
    },
    companyName: {
        id: "global.oprAddress.0.value",
    },
    companyAddress: {
        id: "global.oprAddress.1.value",
    },
    companyZip: {
        id: "global.oprAddress.2.value",
    },
    businessID: {
        id: "footer.oprBusinessId",
    },
});
