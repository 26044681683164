import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageTitle: {
        id: "noLoanPage.pageTitle",
    },
    heading: {
        id: "noLoanPage.pageHeading",
    },
    linkText: {
        id: "noLoanPage.applicationLink.displayText",
    },
    url: {
        id: "noLoanPage.applicationLink.url",
    },
    bodyText: {
        id: "noLoanPage.bodyText",
    },
});
