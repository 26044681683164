import { defineMessages } from "react-intl";

export const messages = defineMessages({
    frontPageTitle: {
        id: "frontPage.pageTitle",
    },
    frontPageHeadingSummary: {
        id: "frontPage.frontPageAccountDetailBlock.loanSummaryHeading",
    },
    frontPageSummaryAvailableText: {
        id: "frontPage.frontPageAccountDetailBlock.creditAvailableText",
    },
    frontPageSummaryWithdrawnText: {
        id: "frontPage.frontPageAccountDetailBlock.creditWithdrawnText",
    },
    frontPageSummaryCreditlimitText: {
        id: "frontPage.frontPageAccountDetailBlock.creditLimitText",
    },
    frontPageSummaryInfo: {
        id: "frontPage.frontPageAccountDetailBlock.creditInfoText",
    },
    recentNewsTitle: {
        id: "frontPage.frontPageNewsBlock.blockTitle",
    },
    recentNewsReadMore: {
        id: "frontPage.frontPageNewsBlock.readMoreText",
    },
    withdrawTitle: {
        id: "frontPage.frontPageWithdrawBlock.withdrawTitle",
    },
    withdrawInputLabel: {
        id: "frontPage.frontPageWithdrawBlock.withdrawInputLabel",
    },
    withdrawInputplaceholder: {
        id: "frontPage.frontPageWithdrawBlock.withdrawInputPlaceholder",
    },
    withdrawButtontext: {
        id: "frontPage.frontPageWithdrawBlock.withdrawButtonText",
    },
    withdrawButtonInfoText: {
        id: "frontPage.frontPageWithdrawBlock.withdrawButtonInfoText",
    },
    withdrawInfo1: {
        id: "frontPage.frontPageWithdrawBlock.withdwrawInfoBullets.1",
    },
    withdrawInfo2: {
        id: "frontPage.frontPageWithdrawBlock.withdwrawInfoBullets.2",
    },
    withdrawInfo3: {
        id: "frontPage.frontPageWithdrawBlock.withdwrawInfoBullets.3",
    },
    withdrawInfo4: {
        id: "frontPage.frontPageWithdrawBlock.withdwrawInfoBullets.4",
    },
    withdrawSentMessage: {
        id: "frontPage.frontPageWithdrawBlock.withdrawSentMessage",
    },
    withdrawAlertText1: {
        id: "frontPage.frontPageWithdrawBlock.withdrawAlertAmountTooBig",
    },
    withdrawAlertText2: {
        id: "frontPage.frontPageWithdrawBlock.withdrawAlertAmountTooSmall",
    },
    withdrawOverdueInvoice: {
        id: "frontPage.frontPageWithdrawBlock.withdrawOverdueInvoice",
    },
    withdrawAvailableCreditTooSmall: {
        id: "frontPage.frontPageWithdrawBlock.withdrawAvailableCreditTooSmall",
    },
    withdrawNotAbleToMakeWithdrawal: {
        id: "frontPage.frontPageWithdrawBlock.withdrawNotAbleToMakeWithdrawal",
    },
    withdrawNoIbanNumber: {
        id: "frontPage.frontPageWithdrawBlock.withdrawNoIbanNumber",
    },
    euroCurrency: {
        id: "frontPage.frontPageWithdrawBlock.withdrawCurrencySign",
    },
    notEligibleText: {
        id: "frontPage.frontPageWithdrawBlock.withdrawNotEligibleText",
    },
    collectionStateHeading: {
        id: "frontPage.frontPageCollection.heading",
    },
    collectionStateText: {
        id: "frontPage.frontPageCollection.bodyText",
    },
    collectionStatePhoneText: {
        id: "global.phoneNumberText",
    },
    collectionStatePhoneNumber: {
        id: "frontPage.frontPageCollection.collectionPhone",
    },
    collectionStateEmailText: {
        id: "global.emailText",
    },
    collectionStateEmailLinkLink: {
        id: "frontPage.frontPageCollection.collectionEmailLink",
    },
    collectionStateEmailLinkText: {
        id: "frontPage.frontPageCollection.collectionEmailAddress",
    },
    loanDocumentsButtonText: {
        id: "frontPage.frontPageCollection.documentBtnText",
    },
});
