import { take, put, call, select, takeLatest, race } from "redux-saga/effects";

import { recentNewsActions } from "@opr-finance/feature-contentful";
import { errorActions } from "@opr-finance/feature-error";
import { smeLoanAccountActions } from "@opr-finance/feature-account";
import { smeWithdrawActions } from "@opr-finance/feature-withdraw";
import { companyActions, engagementActions } from "@opr-finance/feature-sme-customer";
import { history } from "@opr-finance/utils";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { AppState, E_Routes } from "../types/general";
import {
    checkSession,
    getAccountData,
    getInvoiceData,
    getTransactionsData,
    setInvoiceConfig,
    setTransactionsConfig,
} from "./common.saga";
import { AppActionConstants, appActions } from "../actions/actions";
import { getGwProps } from "@opr-finance/utils/src/getGwProps";
import { T_GatewayProps } from "@opr-finance/utils/src/types/general";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* watchFrontPageTrigger() {
    yield takeLatest(AppActionConstants.FRONT_PAGE_TRIGGER, handleFrontPageTrigger);
}

export function* handleFrontPageTrigger() {
    const gw: T_GatewayProps = getGwProps();

    try {
        const state: AppState = yield select((state: AppState) => state);
        const { account, customer, session } = state;
        const accountConfigUrl = account.config.gwUrl;
        const companyConfigUrl = customer.companyInfo.config.gwUrl;
        const token = session.token;
        if (token) {
            yield call(checkSession);
            yield put(engagementActions.engagementTrigger());

            const { engagementSuccess, engagementError } = yield race({
                engagementSuccess: take(engagementActions.engagementSuccess),
                engagementError: take(engagementActions.engagementError),
            });

            if (engagementError) {
                logger.log("error getting engagements");
                window.location.href = "/error";
            }

            if (!accountConfigUrl) {
                yield put(
                    smeLoanAccountActions.accountInitializer({
                        mockApiCalls: gw.mock,
                        gwUrl: gw.fullApiUrl,
                        token,
                        errorUrl: E_Routes.ERROR,
                        noAuth: E_Routes.EXPIRED,
                        noLoanUrl: E_Routes.NO_LOAN,
                    })
                );
            }
            if (!companyConfigUrl) {
                yield put(
                    companyActions.companyDataInitializer({
                        token,
                        gwUrl: gw.fullApiUrl,
                        mock: gw.mock,
                    })
                );
            }
            yield put(
                recentNewsActions.fetchRecentNewsTrigger({
                    clientParams: {
                        space: process.env.REACT_APP_CONTENTFUL_SPACE as string,
                        accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN as string,
                    },
                    contentParams: {
                        select: "fields,sys.createdAt",
                        content_type: "recentNews",
                        "fields.language[in]": "nl",
                        "fields.application[in]": "Flex Online Netherlands",
                    },
                })
            );
            yield take(recentNewsActions.fetchRecentNewsSuccess);
            yield call(getAccountData);
            yield call(setInvoiceConfig);
            yield call(setTransactionsConfig);
            yield put(
                smeWithdrawActions.withdrawInitializer({
                    mockApiCalls: gw.mock,
                    gwUrl: gw.fullApiUrl,
                    token,
                })
            );
            yield call(getInvoiceData);
            yield call(getTransactionsData);
            yield put(appActions.frontPageSuccess());
        }
    } catch (e) {
        history.push(E_Routes.ERROR);
        yield put(
            errorActions.errorTrigger({ message: "frontpage load failed" + e, url: "/error" })
        );
    }
}
