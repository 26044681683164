import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { ContactPageStyles } from "@opr-finance/theme-flex-online";
import { StyledPageTitle } from "@opr-finance/component-content";
import { StyledGrid } from "@opr-finance/component-grid";
import { Font } from "@opr-finance/component-fonts";
import { Link } from "@opr-finance/component-link-to";

import { ContactPageProps } from "./types";
import { AppState, E_Routes } from "../../types/general";
import { messages } from "./messages";

export function ContactPage(props: ContactPageProps) {
    const { formatMessage: fm } = useIntl();
    const authenticated = useSelector((state: AppState) => state.session.authenticated);

    if (!authenticated) {
        return <Redirect to={E_Routes.ROOT} />;
    }

    return (
        <StyledGrid styleConfig={{ root: ContactPageStyles.contactPageRootStyles() }}>
            <StyledPageTitle
                title={fm(messages.pageTitle)}
                styleConfig={{
                    pageTitleContainer: props.styleConfig.titleBox,
                    pageTitleText: props.styleConfig.pageTitle,
                }}
            />
            <StyledGrid styleConfig={{ root: ContactPageStyles.contactPageInfoContainer() }}>
                <StyledGrid styleConfig={{ root: ContactPageStyles.contactPageInfoBox() }}>
                    <Font styleConfig={{ root: props.styleConfig.textStyle.boxTitle }} as="p">
                        {fm(messages.headingContact)}
                    </Font>
                    <Font styleConfig={{ root: props.styleConfig.textStyle.boldedText }} as="p">
                        {fm(messages.contactInfoSubheading)}
                    </Font>
                    <Font styleConfig={{ root: props.styleConfig.textStyle.boldedText }} as="p">
                        {fm(messages.textOpen) + " "}
                        <Font styleConfig={{ root: props.styleConfig.textStyle.contentText }}>
                            {fm(messages.textOpeningHours)}
                        </Font>
                    </Font>
                    <Font styleConfig={{ root: props.styleConfig.textStyle.boldedText }} as="p">
                        {fm(messages.textPhone) + " "}
                        <Font styleConfig={{ root: props.styleConfig.textStyle.contentText }}>
                            {fm(messages.textPhoneNumber)}
                        </Font>
                    </Font>
                    <Font styleConfig={{ root: props.styleConfig.textStyle.boldedText }} as="p">
                        {fm(messages.emailText) + " "}
                        <Font styleConfig={{ root: props.styleConfig.textStyle.contentText }}>
                            <Link
                                href={fm(messages.customerServiceEmailLink)}
                                styleConfig={{ root: ContactPageStyles.contactPageLinkEmail() }}
                            >
                                {fm(messages.customerServiceEmailLinkText)}
                            </Link>
                        </Font>
                    </Font>
                    <Font styleConfig={{ root: props.styleConfig.textStyle.boldedText }} as="p">
                        {fm(messages.checkFaqSubheading)}
                    </Font>
                    <Font styleConfig={{ root: props.styleConfig.textStyle.contentText }}>
                        <Link
                            href={`${process.env.REACT_APP_FAQ_URL}`}
                            target="_blank"
                            styleConfig={{ root: ContactPageStyles.contactPageLinkFaq() }}
                        >
                            {fm(messages.FAQLinkText)}
                        </Link>
                    </Font>
                </StyledGrid>
                <StyledGrid styleConfig={{ root: ContactPageStyles.contactPageInfoBox() }}>
                    <Font styleConfig={{ root: props.styleConfig.textStyle.boxTitle }} as="p">
                        {fm(messages.headingGeneral)}
                    </Font>
                    <Font styleConfig={{ root: props.styleConfig.textStyle.boldedText }} as="p">
                        {fm(messages.moreTimeQuestion)}
                    </Font>
                    <Font styleConfig={{ root: props.styleConfig.textStyle.contentText }} as="p">
                        {fm(messages.moreTimeAnswer)}
                    </Font>
                    <Font styleConfig={{ root: props.styleConfig.textStyle.boldedText }} as="p">
                        {fm(messages.additionalFundQuestion)}
                    </Font>
                    <Font styleConfig={{ root: props.styleConfig.textStyle.contentText }} as="p">
                        {fm(messages.additionalFundAnswer)}

                        <Link
                            href={`${process.env.REACT_APP_APPLICATION}`}
                            target="_blank"
                            styleConfig={{ root: ContactPageStyles.contactPageLink() }}
                        >
                            {fm(messages.textContent2LinkText)}
                        </Link>
                    </Font>
                    <Font styleConfig={{ root: props.styleConfig.textStyle.boldedText }} as="p">
                        {fm(messages.collectionQuestion)}
                    </Font>
                    <Font styleConfig={{ root: props.styleConfig.textStyle.contentText }} as="p">
                        {fm(messages.collectionAnswer)}
                    </Font>
                    <Font styleConfig={{ root: ContactPageStyles.contactPageTextWrapper() }} as="p">
                        {fm(messages.sveaPhone)}
                    </Font>
                    <Font styleConfig={{ root: ContactPageStyles.contactPageTextWrapper() }} as="p">
                        {fm(messages.emailText) + " "}
                        <Link
                            href={"mailto:" + fm(messages.sveaEmailLink)}
                            styleConfig={{ root: ContactPageStyles.contactPageLinkEmail() }}
                        >
                            {fm(messages.sveaEmailText)}
                        </Link>
                    </Font>
                </StyledGrid>
            </StyledGrid>
        </StyledGrid>
    );
}
