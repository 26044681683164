import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageTitle: {
        id: "customerServicePage.pageTitle",
    },
    headingContact: {
        id: "customerServicePage.contactInfoHeading",
    },
    headingGeneral: {
        id: "customerServicePage.GeneralInfoHeading",
    },
    customerServiceEmailLink: {
        id: "global.customerServiceEmail.url",
    },
    customerServiceEmailLinkText: {
        id: "global.customerServiceEmail.displayText",
    },
    emailText: {
        id: "global.emailText",
    },
    FAQLinkText: {
        id: "customerServicePage.faqLink.displayText",
    },
    FAQLink: {
        id: "customerServicePage.faqLink.url",
    },
    contactInfoSubheading: {
        id: "customerServicePage.contactInfoSubHeading",
    },
    checkFaqSubheading: {
        id: "customerServicePage.checkFAQText",
    },
    moreTimeQuestion: {
        id: "customerServicePage.moreTimeQuestion",
    },
    moreTimeAnswer: {
        id: "customerServicePage.moreTimeAnswer",
    },
    additionalFundQuestion: {
        id: "customerServicePage.additionalFundQuestion",
    },
    additionalFundAnswer: {
        id: "customerServicePage.additionalFundAnswer",
    },
    collectionQuestion: {
        id: "customerServicePage.collectionQuestion",
    },
    collectionAnswer: {
        id: "customerServicePage.collectionAnswer",
    },
    textOpen: {
        id: "customerServicePage.openText",
    },
    textOpeningHours: {
        id: "global.openingHours",
    },
    textPhone: {
        id: "global.phoneNumberText",
    },
    textPhoneNumber: {
        id: "global.phoneNumber",
    },
    textContent2LinkText: {
        id: "customerServicePage.additionalFundingHereLink.displayText",
    },
    textContent2Link: {
        id: "customerServicePage.additionalFundingHereLink.url",
    },
    sveaPhone: {
        id: "customerServicePage.collectionPhoneNumber",
    },
    sveaEmailLink: {
        id: "customerServicePage.collectionEmail",
    },
    sveaEmailText: {
        id: "customerServicePage.collectionEmail",
    },
});
