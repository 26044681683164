import { useIntl } from "react-intl";
import { Font } from "@opr-finance/component-fonts";
import { StyledLink } from "@opr-finance/component-link-to";
import { StyledGrid } from "@opr-finance/component-grid";
import { T_ColumnsProps } from "./types";
import { messages } from "../../pages/LoanPage/messages";
import { T_FormattedInvoice } from "@opr-finance/feature-statements";

export const invoicesColumns = (props: T_ColumnsProps) => {
    const { formatMessage: fm } = useIntl();
    const date = new Date();
    return [
        {
            name: fm(messages.tableInvoiceNumber),
            cell: (rowData) => (
                <StyledLink
                    styleConfig={{
                        root: props.styleConfig.invoicesTableLink,
                    }}
                    onClick={(event) => props.onClick(event, rowData.documentId)}
                    target="_blank">
                    {rowData.seriesNum}
                </StyledLink>
            ),
        },
        {
            name: fm(messages.tableInvoiceType),
            cell: (rowData) => (
                <StyledLink
                    styleConfig={{
                        root: props.styleConfig.invoicesTableLink,
                    }}
                    onClick={(event) => props.onClick(event, rowData.documentId)}
                    target="_blank">
                    {rowData.title}
                </StyledLink>
            ),
        },
        {
            name: fm(messages.tableInvoiceStatus),
            selector: (rowData) => {
                switch (rowData.status) {
                    case "OVERDUE":
                        return fm(messages.invoiceStatusOverdue);
                    case "PAIDINFULL":
                        return fm(messages.invoiceStatusPaid);
                    case "UNPAID":
                    case "PARTIALLYPAID":
                        return fm(messages.invoiceStatusOpen);
                    case "CREDIT": {
                        const amount = rowData.invoiceAmount - rowData.paidAmount;
                        if (amount >= 0.01 && rowData.dueDate > date)
                            return fm(messages.invoiceStatusOpen);
                        if (amount >= 0.01 && rowData.dueDate < date)
                            return fm(messages.invoiceStatusOverdue);
                        if (amount === 0.0) return fm(messages.invoiceStatusPaid);
                        return rowData.status;
                    }
                    default:
                        return rowData.status;
                }
            },
        },
        {
            name: fm(messages.invoiceTableDueDate),
            selector: (rowData: T_FormattedInvoice) => (rowData.dueDate ? rowData.dueDate : ""),
        },

        {
            name: fm(messages.tableAmount),
            selector: (rowData: T_FormattedInvoice) => rowData.amount,
        },
    ];
};

export const invoicesMobileColumns = (getPdfClick, styleConfig) => {
    const { formatMessage: fm } = useIntl();
    const date = new Date();

    const filterTypes = (rowData: any) => {
        switch (rowData.status) {
            case "OVERDUE":
                return fm(messages.invoiceStatusOverdue);
            case "PAIDINFULL":
                return fm(messages.invoiceStatusPaid);
            case "UNPAID":
            case "PARTIALLYPAID":
                return fm(messages.invoiceStatusOpen);
            case "CREDIT": {
                const amount = rowData.invoiceAmount - rowData.paidAmount;
                if (amount >= 0.01 && rowData.dueDate > date) return fm(messages.invoiceStatusOpen);
                if (amount >= 0.01 && rowData.dueDate < date)
                    return fm(messages.invoiceStatusOverdue);
                if (amount === 0.0) return fm(messages.invoiceStatusPaid);
                return rowData.status;
            }
            default:
                return rowData.status;
        }
    };

    return [
        {
            name: "",
            cell: (rowData: any) => {
                const statementStatus = filterTypes(rowData);

                return (
                    <StyledGrid styleConfig={{ root: styleConfig.mobileTableContainer }}>
                        <StyledGrid styleConfig={{ root: styleConfig.invoiceInfoContainer }}>
                            <StyledLink
                                styleConfig={{
                                    root: { ...styleConfig.invoicesTableText, fontWeight: "bold" },
                                }}
                                onClick={(event) => getPdfClick(event, rowData.documentId)}
                                target="_blank">
                                {rowData.title}
                            </StyledLink>
                            <Font styleConfig={{ root: styleConfig.invoicesTableText }}>
                                {statementStatus}
                            </Font>
                            <Font styleConfig={{ root: styleConfig.invoicesTableText }}>
                                {rowData.dueDate}
                            </Font>
                        </StyledGrid>
                        <StyledGrid styleConfig={{ root: styleConfig.invoiceAmountContainer }}>
                            <Font styleConfig={{ root: styleConfig.invoicesTableText }}>
                                {rowData.amount}
                            </Font>
                        </StyledGrid>
                    </StyledGrid>
                );
            },
        },
    ];
};
