import { defineMessages } from "react-intl";

export const messages = defineMessages({
    frontPage: {
        id: "header.frontPage",
    },
    loanPage: {
        id: "header.loanInformation",
    },
    topUpPage: {
        id: "header.topup",
    },
    userInfo: {
        id: "header.ownInfo",
    },
    contactInfo: {
        id: "header.customerService",
    },
    logout: {
        id: "header.logoutText",
    },
    logIn: {
        id: "header.loginText",
    },
    mobileTopUp: {
        id: "header.topup",
    },
    mobileLoanInfo: {
        id: "header.loanInformation",
    },
    mobileNavContact: {
        id: "header.ownInfo",
    },
    mobileNavCustomerService: {
        id: "header.customerService",
    },
    mobileMore: {
        id: "header.mobileNavMore",
    },
});
