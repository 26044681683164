export enum StrapiCmsActionsConstants {
    FETCH_TRANSLATIONS_TRIGGER = "GLOBAL/FETCH_STRAPI_TRIGGER",
    FETCH_TRANSLATIONS_SUCCESS = "GLOBAL/FETCH_STRAPI_SUCCESS",
}

type T_Label = "Alert" | "None" | "Critical";

export type T_Message = {
    [key: string]: string;
};

export type T_News = {
    active: boolean;
    newsHeading: string;
    news: string;
    publishedOn: Date;
    id: number;
};

export type T_Notice = {
    active: boolean;
    notice: string;
    label: T_Label;
    id: number;
};

export type T_Countries = "fi" | "sv" | "nl" | "cz";
export enum E_Applications {
    fi = "Flex Online FI",
    nl = "Flex Online NL",
    se = "Flex Online SE",
}

export type T_StrapiCmsPayload = {
    locale: T_Countries;
    gwUrl: string;
};

export type T_StrapiCmsInitializerConfig = {
    gwUrl: string;
    locale: T_Countries | undefined;
};

export type T_StrapiData = {
    translations: T_Message;
    news: T_News;
    notice: T_Notice;
};
export type T_TranslationSuccessPayload = {
    strapiData: T_StrapiData;
    gwUrl: string;
    locale: T_Countries;
};

export type T_StrapiCmsState = {
    messages: T_Message | null;
    notices: T_Notice[];
    news: T_News[];
    config: T_StrapiCmsInitializerConfig;
};

export type T_FeatureStrapiCmsReducerState = {
    strapiCms: T_StrapiCmsState;
};

export type T_StrapiResponse = {
    data: {
        attributes: any;
    };
};
