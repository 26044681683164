import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageTitle: {
        id: "topupPage.pageTitle",
    },
    alertTextRequired: {
        id: "Required field",
    },
    dropdownChoose: {
        id: "topupPage.dropdownChooseText",
    },
    heading: {
        id: "topupPage.pageSubHeading",
    },
    topTextInfoLongText: {
        id: "topupPage.pageLongInfoText",
    },
    topFormInstructionText: {
        id: "topupPage.formInstructionText",
    },

    //loan info ---
    loanInfoSubHead: {
        id: "topupPage.loanInfoSubHeading",
    },
    textCreditLimit: {
        id: "topupPage.creditLimitLabel",
    },
    textNewCreditLimit: { id: "topupPage.newCreditLimitText" },
    NewCreditLimitLabel: {
        id: "topupPage.newCreditLimit.label",
    },
    NewCreditLimitPlaceholder: {
        id: "topupPage.newCreditLimit.placeholder",
    },
    NewCreditLimitErrorRequired: {
        id: "topupPage.newCreditLimit.errorMessages.required",
    },
    NewCreditLimitErrorWrongAmount: {
        id: "topupPage.newCreditLimit.errorMessages.amountRequirement",
    },
    acceptLowerLimitLabel: {
        id: "topupPage.acceptLowerLimitCheckBox.label",
    },

    // Company info ---
    companyInfoSubHead: {
        id: "topupPage.organizationInfoSubHead",
    },
    companyName: {
        id: "topupPage.organizationNameLabel",
    },
    businessID: {
        id: "topupPage.organizationNumberLabel",
    },
    textMonhtlyTurnover: { id: "topupPage.monthlyTurnoverText" },
    monhtlyTurnoverLabel: {
        id: "topupPage.monthlyTurnover.label",
    },
    monhtlyTurnoverPlaceholder: {
        id: "topupPage.monthlyTurnover.placeholder",
    },
    monhtlyTurnoverErrorRequired: {
        id: "topupPage.monthlyTurnover.errorMessages.required",
    },
    emailText: {
        id: "global.emailText",
    },

    //Guarantor info ---
    guarantorInfoSubHead: {
        id: "topupPage.guarantorInfoSubHead",
    },
    guaranteeName: {
        id: "topupPage.guarantorNameLabel",
    },
    guaranteeSSN: {
        id: "topupPage.guarantorSSnLabel",
    },
    guaranteePhone: {
        id: "global.phoneNumberText",
    },
    campaignCode: {
        id: "topupPage.campaignCode.label",
    },
    campaignCodePlaceholder: {
        id: "topupPage.campaignCode.placeholder",
    },

    creditCheck: {
        id: "topupPage.creditCheck.label",
    },
    creditCheckError: {
        id: "topupPage.creditCheck.errorMessages.required",
    },
    privacyPolicy: {
        id: "topupPage.PrivacyPolicy.label",
    },
    privacyPolicyError: {
        id: "topupPage.PrivacyPolicy.errorMessages.required",
    },
    privacyPolicyLinkText: {
        id: "topupPage.privacyPolicyLink.displayText",
    },
    privacyPolicyUrl: {
        id: "topupPage.privacyPolicyLink.url",
    },
    checkboxInfo2Modal: {
        id: "topupPage.PrivacyPolicy.label",
    },
    buttontextPreview: {
        id: "topupPage.previewBtnText",
    },
    buttonTextSend: {
        id: "topupPage.sendApplicationBtnText",
    },
    buttonTextGoback: {
        id: "topupPage.backBtnText",
    },
    fillAllFieldsAlertText: {
        id: "topupPage.fillInAllFieldsErrorText",
    },
    customerServiceLink: {
        id: "global.customerServiceEmail.url",
    },
    customerServiceLinkText: {
        id: "global.customerServiceEmail.displayText",
    },
    sentModalTitle: {
        id: "topupPage.ThankYouHeading",
    },
    sentModalInfo1: {
        id: "topupPage.thankYouBodyText1",
    },
    sentModalInfo2: {
        id: "topupPage.thankYouBodyText2",
    },
    sentModalInfo3: {
        id: "topupPage.thankyouBodyOrByPhoneText",
    },

    //Not-Eligible block
    notEligibleTitle: {
        id: "topupPage.topupPageNotEligibleBlock.blockTitle",
    },
    notEligibleSubhead1: {
        id: "topupPage.topupPageNotEligibleBlock.blockSubHead1",
    },
    notEligibleMaxCreditLimitText: {
        id: "topupPage.topupPageNotEligibleBlock.maxCreditLimitText",
    },
    notEligibleInfoPoint1: {
        id: "topupPage.topupPageNotEligibleBlock.infoPoint1",
    },
    notEligibleInfoPoint2: {
        id: "topupPage.topupPageNotEligibleBlock.infoPoint2",
    },
    notEligibleSubhead2: {
        id: "topupPage.topupPageNotEligibleBlock.blockSubHead2",
    },
    phoneText: {
        id: "global.phoneNumberText",
    },
    phoneNumber: {
        id: "global.phoneNumber",
    },
    customerServiceEmailText: {
        id: "global.customerServiceEmail.displayText",
    },
    customerServiceEmailLink: {
        id: "global.customerServiceEmail.url",
    },
});
