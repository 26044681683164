import { getGwProps } from "@opr-finance/utils/src/getGwProps";
import { T_GatewayProps } from "@opr-finance/utils/src/types/general";

const gw: T_GatewayProps = getGwProps();

export async function getProfileData() {
    const url = `${gw.fullApiUrl}/profile?source=${sessionStorage.getItem(
        "source"
    )}&email=${sessionStorage.getItem("email")}`;
    const token = localStorage.getItem("token");

    const result = await fetch(url, {
        method: "GET",
        headers: {
            "content-type": "application/json",
            authorization: token as string,
        },
    });

    return result;
}
