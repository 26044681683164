import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { StyledPageTitle } from "@opr-finance/component-content";
import { StyledGrid } from "@opr-finance/component-grid";
import { NlStartPageStyles } from "@opr-finance/theme-flex-online";
import { Font } from "@opr-finance/component-fonts";
import { StyledButton } from "@opr-finance/component-button";
import { useQuery } from "@opr-finance/utils";

import { QueryParams, StartPageProps } from "./types";
import { Notice } from "../../components/Notice";
import { messages } from "./messages";
import { AppState, T_Notice } from "../../types/general";
import { getGwProps } from "@opr-finance/utils/src/getGwProps";
import { T_GatewayProps } from "@opr-finance/utils/src/types/general";

export function StartPage(props: StartPageProps) {
    const { formatMessage: fm } = useIntl();
    const [isRedirectedFromApplicationPage, setIsRedirectedFromApplicationPage] =
        useState<boolean>(false);
    const params = useQuery<QueryParams>();

    const gw: T_GatewayProps = getGwProps();

    const pipelineUrl = process.env.REACT_APP_PIPELINE_URL as string;

    const notices = useSelector((state: AppState) => state.strapiCms.notices);
    const activeNotices = notices.filter((notice: T_Notice) => notice.active);

    useEffect(() => {
        if (params.source) {
            setIsRedirectedFromApplicationPage(true);
            sessionStorage.setItem("source", params.source);
            sessionStorage.setItem("email", params.email);
        }
    }, []);

    return (
        <StyledGrid styleConfig={{ root: NlStartPageStyles.startPageRootStyles() }}>
            {activeNotices &&
                activeNotices.length > 0 &&
                activeNotices.map((notice: T_Notice) => (
                    <StyledGrid
                        key={notice.id.toString()}
                        styleConfig={{ root: NlStartPageStyles.startPageNoticeListContainer() }}>
                        <Notice
                            notice={notice.notice}
                            styleConfig={{
                                noticeContainer: NlStartPageStyles.startPageNoticeContainer({
                                    label: notice.label,
                                }),
                                notice: NlStartPageStyles.startPageNotice(),
                            }}
                        />
                    </StyledGrid>
                ))}
            <StyledPageTitle
                title={fm(messages.pageTitle)}
                styleConfig={{
                    pageTitleContainer: props.styleConfig.titleBox,
                    pageTitleText: props.styleConfig.pageTitle,
                }}
            />
            <StyledGrid styleConfig={{ root: NlStartPageStyles.startPageContainer() }}>
                <StyledGrid styleConfig={{ root: NlStartPageStyles.startPageLoginPath() }}>
                    <Font styleConfig={{ root: NlStartPageStyles.startPagePathHeading() }} as="p">
                        {fm(messages.loginTitle)}
                    </Font>
                    <StyledButton
                        onClick={() => {
                            window.location.href = gw.fullAuthUrl;
                        }}
                        styleConfig={{
                            root: props.styleConfig.loginButton,
                        }}>
                        <Font
                            styleConfig={{
                                root: props.styleConfig.buttonText,
                            }}>
                            {fm(messages.loginButtonText)}
                        </Font>
                    </StyledButton>
                    {!isRedirectedFromApplicationPage && (
                        <>
                            <Font
                                styleConfig={{
                                    root: NlStartPageStyles.startPageApplyForLoan(),
                                }}
                                as="p">
                                {fm(messages.applyTitle)}
                                <Font
                                    styleConfig={{
                                        root: NlStartPageStyles.startPageApplyForLoanLink(),
                                    }}
                                    as="span"
                                    onClick={() => {
                                        window.location.href = pipelineUrl;
                                    }}>
                                    {fm(messages.applyLinkText)}
                                </Font>
                            </Font>
                        </>
                    )}
                </StyledGrid>
            </StyledGrid>
        </StyledGrid>
    );
}
