import React, { useState } from "react";
import { format } from "date-fns";

import { StyledGrid } from "@opr-finance/component-grid";
import {
    ButtonStyles,
    FontsStyles,
    LoanPageStyles,
    ModalStyles,
} from "@opr-finance/theme-flex-online";
import { Font } from "@opr-finance/component-fonts";
import { Icon } from "@opr-finance/component-icon";
import { Link } from "@opr-finance/component-link-to";
import { Modal } from "@opr-finance/component-modal-dialog";
import { StyledButton } from "@opr-finance/component-button";

import { useIntl } from "react-intl";
import { T_InfoAndContactProps } from "./types";
import { messages } from "../../pages/LoanPage/messages";
import { DateFormat } from "@opr-finance/component-date";

export function InfoAndContactBlock(props: T_InfoAndContactProps) {
    const { formatMessage: fm } = useIntl();

    const [modalOpen, toggleModalOpen] = useState(false);

    return (
        <StyledGrid styleConfig={{ root: LoanPageStyles.loanPageInfoContainer() }}>
            <StyledGrid styleConfig={{ root: LoanPageStyles.loanPageInfoBox() }}>
                <Font styleConfig={{ root: FontsStyles.fontBoxTitle() }} as="p">
                    {fm(messages.pageTitle)}
                </Font>
                <Font styleConfig={{ root: FontsStyles.fontContentText() }} as="p">
                    {`${fm(messages.infoBlockDateText) + " "}${format(
                        new Date(),
                        DateFormat.Netherlands
                    )}`}
                </Font>
                <Font styleConfig={{ root: FontsStyles.fontAmountHeading() }} as="p">
                    {props.availableBalanceCurrency}
                </Font>
                <Font styleConfig={{ root: FontsStyles.fontContentText() }} as="p">
                    {fm(messages.infoBlockAmountInfo)}
                </Font>
                {props.documentInUse ? (
                    props.defaultPromissoryNoteId ? (
                        <StyledButton
                            onClick={() => toggleModalOpen(!modalOpen)}
                            styleConfig={{ root: ButtonStyles.greenButtonStyles() }}>
                            <Font styleConfig={{ root: ButtonStyles.buttonFontStyles() }}>
                                {fm(messages.loanDocumentsButtonText)}
                                <Icon icon={["fa", "angle-double-right"]} />{" "}
                            </Font>
                        </StyledButton>
                    ) : (
                        <Font styleConfig={{ root: FontsStyles.fontContentText(true) }} as="p">
                            {fm(messages.documentsError)}
                        </Font>
                    )
                ) : (
                    <p></p>
                )}
                <Modal
                    modalTitle={fm(messages.modalTitle)}
                    isOpen={modalOpen}
                    onClick={() => toggleModalOpen(false)}
                    styleConfig={{
                        closeIcon: ModalStyles.modalCloseIcon(),
                        overlay: ModalStyles.modalOverlay(),
                        content: ModalStyles.modalContentScroll({ height: ["430px", "330px"] }),
                        title: ModalStyles.modalTitle(),
                        titleText: ModalStyles.titleText(),
                    }}>
                    <StyledGrid styleConfig={{ root: LoanPageStyles.modalLayout() }}>
                        <Font styleConfig={{ root: FontsStyles.fontContentText() }} as="p">
                            {fm(messages.modalContent)}
                        </Font>
                        <StyledButton
                            onClick={props.handleClick}
                            styleConfig={{
                                root: ButtonStyles.greenButtonStyles({ marginTop: "42px" }),
                            }}>
                            <Font styleConfig={{ root: ButtonStyles.buttonFontStyles() }}>
                                {fm(messages.modalButtonText)}
                                <Icon icon={["fa", "angle-double-right"]} />{" "}
                            </Font>
                        </StyledButton>
                    </StyledGrid>
                </Modal>
            </StyledGrid>
            <StyledGrid styleConfig={{ root: LoanPageStyles.loanPageInfoBox() }}>
                <Font styleConfig={{ root: FontsStyles.fontBoxTitle() }} as="p">
                    {fm(messages.contactBlockHeading)}
                </Font>
                <Font styleConfig={{ root: FontsStyles.fontContentText() }} as="p">
                    {fm(messages.contactBlockRequestBalanceText)}
                </Font>
                <StyledGrid
                    styleConfig={{ root: LoanPageStyles.loanPageInfoBoxContactContainer() }}>
                    <Font styleConfig={{ root: FontsStyles.fontBoldedTextSpan() }} as="span">
                        {fm(messages.textPhone)}
                    </Font>
                    <Font styleConfig={{ root: FontsStyles.fontContentText() }} as="span">
                        {fm(messages.textPhoneNumber)}
                    </Font>
                </StyledGrid>

                <Font styleConfig={{ root: FontsStyles.fontBoldedText() }} as="p">
                    {fm(messages.textEmail) + " "}
                </Font>
                <Font styleConfig={{ root: FontsStyles.fontContentText() }}>
                    <Link
                        href={fm(messages.customerServiceEmailLink)}
                        styleConfig={{ root: LoanPageStyles.loanPageLinkEmail() }}>
                        {fm(messages.customerSerciceEmailText)}{" "}
                    </Link>
                </Font>
                <Font styleConfig={{ root: FontsStyles.fontBoldedText() }} as="p">
                    {fm(messages.contactBlockOpeningHrs)}
                </Font>
            </StyledGrid>
        </StyledGrid>
    );
}
