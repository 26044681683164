import { call, put, takeLatest, race, take } from "redux-saga/effects";

import { errorActions } from "@opr-finance/feature-error";
import { engagementActions } from "@opr-finance/feature-sme-customer";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { AppActionConstants, appActions } from "../actions/actions";
import {
    getAccountData,
    getInvoiceData,
    getTransactionsData,
    setAccountConfig,
    setInvoiceConfig,
    setReportingConfig,
    setTransactionsConfig,
} from "./common.saga";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* watchLoanPageTrigger() {
    yield takeLatest(AppActionConstants.LOAN_PAGE_TRIGGER, handleLoanPageTrigger);
}

export function* handleLoanPageTrigger() {
    try {
        yield put(engagementActions.engagementTrigger());

        const { engagementSuccess, engagementError } = yield race({
            engagementSuccess: take(engagementActions.engagementSuccess),
            engagementError: take(engagementActions.engagementError),
        });

        if (engagementError) {
            logger.log("error getting engagements");
            window.location.href = "/error";
        }
        yield call(setAccountConfig);
        yield call(getAccountData);
        yield call(setTransactionsConfig);
        yield call(getTransactionsData);
        yield call(setInvoiceConfig);
        yield call(getInvoiceData);
        yield call(setReportingConfig);

        yield put(appActions.loanPageSuccess());
    } catch (e) {
        yield put(
            errorActions.errorTrigger({ message: "loanpage load failed" + e, url: "/error" })
        );
    }
}
