import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageTitle: {
        id: "accountSelectionPage.pageHeading",
    },
    loanNumber: {
        id: "accountSelectionPage.loanAccountNumberText",
    },
});
