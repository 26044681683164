import {
    E_SmeTransactionStatuses,
    GeneratedMessages,
    generateMessageObject,
    MessageData,
} from "@opr-finance/feature-transactions";
import { defineMessages } from "react-intl";

const transactionStatusesdata: MessageData[] = [
    {
        contentfulKey:
            "loanPage.loanPageTransactionsBlock.transactionStatus.loanAdjustementInterest",
        messageId: E_SmeTransactionStatuses.LOAN_ADJUSTMENT_INTEREST_TRANSACTION,
    },
    {
        contentfulKey: "loanPage.loanPageTransactionsBlock.transactionStatus.disbursement",
        messageId: E_SmeTransactionStatuses.LOAN_TRANCHE_DISBURSEMENT_TRANSACTION,
    },
    {
        contentfulKey: "loanPage.loanPageTransactionsBlock.transactionStatus.payment",
        messageId: E_SmeTransactionStatuses.PAYMENT_TRANSACTION,
    },
    {
        contentfulKey: "loanPage.loanPageTransactionsBlock.transactionStatus.amortization",
        messageId: E_SmeTransactionStatuses.STATEMENT_AMORTIZATION_TRANSACTION,
    },
    {
        contentfulKey: "loanPage.loanPageTransactionsBlock.transactionStatus.latePaymentInterest",
        messageId: E_SmeTransactionStatuses.STATEMENT_LATE_PAYMENT_INTEREST_TRANSACTION,
    },
    {
        contentfulKey: "loanPage.loanPageTransactionsBlock.transactionStatus.statementInterest",
        messageId: E_SmeTransactionStatuses.STATEMENT_INTEREST_TRANSACTION,
    },
    {
        contentfulKey: "loanPage.loanPageTransactionsBlock.transactionStatus.roundingTransaction",
        messageId: E_SmeTransactionStatuses.STATEMENT_ROUNDING_TRANSACTION,
    },
    {
        contentfulKey:
            "loanPage.loanPageTransactionsBlock.transactionStatus.virtualPaymentTransaction",
        messageId: E_SmeTransactionStatuses.VIRTUAL_PAYMENT_TRANSACTION,
    },
    {
        contentfulKey:
            "loanPage.loanPageTransactionsBlock.transactionStatus.manualPaymentTransaction",
        messageId: E_SmeTransactionStatuses.MANUAL_PLACING_PAYMENT_TRANSACTION,
    },
    {
        contentfulKey: "loanPage.loanPageTransactionsBlock.transactionStatus.trancheStartupFee",
        messageId: E_SmeTransactionStatuses.STATEMENT_TRANCHE_STARTUP_FEE_TRANSACTION,
    },
    {
        contentfulKey: "loanPage.loanPageTransactionsBlock.transactionStatus.aggregatedInterest",
        messageId: E_SmeTransactionStatuses.AGGREGATED_INTEREST_TRANSACTION,
    },
];

const generatedTransactionsMessages: GeneratedMessages =
    generateMessageObject(transactionStatusesdata);

export const messages = defineMessages({
    pageTitle: {
        id: "loanPage.loanPageTitle",
    },
    loanInfoHeading: {
        id: "loanPage.loanPageInfoBlock.blockHeading",
    },
    infoBlockDateText: {
        id: "loanPage.loanPageInfoBlock.dateInfoText",
    },
    infoBlockAmountInfo: {
        id: "loanPage.loanPageInfoBlock.amountInfoText",
    },
    contactBlockHeading: {
        id: "loanPage.loanPageContactBlock.blockHeading",
    },
    contactBlockRequestBalanceText: {
        id: "loanPage.loanPageContactBlock.requestBalanceText",
    },
    contactBlockOpeningHrs: {
        id: "loanPage.loanPageContactBlock.customerServiceOpeningHrsText",
    },
    textPhone: {
        id: "global.phoneNumberText",
    },
    textPhoneNumber: {
        id: "global.phoneNumber",
    },
    textEmail: {
        id: "global.emailText",
    },
    customerServiceEmailLink: {
        id: "global.customerServiceEmail.url",
    },
    customerSerciceEmailText: {
        id: "global.customerServiceEmail.displayText",
    },
    loanDocumentsButtonText: {
        id: "loanPage.loanPageInfoBlock.loanDocumentBtnText",
    },
    modalTitle: {
        id: "loanPage.loanPageInfoBlock.modalTitle",
    },
    modalContent: {
        id: "loanPage.loanPageInfoBlock.modalBodyText",
    },
    modalButtonText: {
        id: "loanPage.loanPageInfoBlock.openDocumentsBtnText",
    },
    TableHeadingTransactions: {
        id: "loanPage.loanPageTransactionsBlock.blockHeading",
    },
    TableDate: {
        id: "loanPage.loanPageTransactionsBlock.tableHeadDateText",
    },
    //TODO: create Strapi content for TableTextButton
    TableTextButton: {
        id: "loanPage",
    },
    documentsError: {
        id: "loanPage.loanPageInfoBlock.documentsErrorText",
    },
    makeWithdrawalBtnText: {
        id: "loanPage.loanPageTransactionsBlock.makeWithdrawlBtnText",
    },

    //reporting block messages
    reportingBlockHeading: {
        id: "loanPage.loanPageReportingBlock.blockHeading",
    },
    reportingBlockNoAccountDataMessage: {
        id: "loanPage.loanPageReportingBlock.noAccountDataMessage",
    },
    reportingBlockInstructionsText: {
        id: "loanPage.loanPageReportingBlock.reportsOverviewInstructionsText",
    },
    reportingBlockNoReportsMessage: {
        id: "loanPage.loanPageReportingBlock.noReportsMessage",
    },
    reportingBlockDateInputPlaceholder: {
        id: "loanPage.loanPageReportingBlock.dateInputPlaceholder",
    },
    reportingBlockButtonText: {
        id: "loanPage.loanPageReportingBlock.buttonText",
    },
    reportingBlockLoadingText: {
        id: "loanPage.loanPageReportingBlock.loadingText",
    },
    reportingBlockErrorText: {
        id: "loanPage.loanPageReportingBlock.errorText",
    },

    TableAmount: {
        id: "loanPage.loanPageTransactionsBlock.tableHeadAmountText",
    },
    TableTransactionsType: {
        id: "loanPage.loanPageTransactionsBlock.tableHeadTransactionTypeText",
    },
    tableHeadingInvoices: {
        id: "loanPage.loanPageInvoiceBlock.blockHeading",
    },
    tableInvoiceType: {
        id: "loanPage.loanPageInvoiceBlock.tableHeadInvoiceTypeText",
    },
    tableInvoiceNumber: {
        id: "loanPage.loanPageInvoiceBlock.tableHeadInvoiceNumber",
    },
    tableAmount: {
        id: "loanPage.loanPageInvoiceBlock.tableHeadSumText",
    },
    tableInvoiceStatus: {
        id: "loanPage.loanPageInvoiceBlock.tableHeadStatusText",
    },
    invoiceTableDueDate: {
        id: "loanPage.loanPageInvoiceBlock.tableHeadDueDateText",
    },
    invoiceStatusOverdue: {
        id: "loanPage.loanPageInvoiceBlock.invoiceOverdue",
    },
    invoiceStatusPaid: {
        id: "loanPage.loanPageInvoiceBlock.invoicePaid",
    },
    invoiceStatusOpen: {
        id: "loanPage.loanPageInvoiceBlock.invoiceOpen",
    },
    ...generatedTransactionsMessages,
});
