import { defineMessages } from "react-intl";

export const messages = defineMessages({
    show: {
        id: "loanPage.genericTable.paginatorShow",
        defaultMessage: "Translation missing",
    },
    noData: {
        id: "loanPage.genericTable.nodata",
        defaultMessage: "Translation missing",
    },
});
