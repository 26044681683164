import React from "react";
import { useSelector } from "react-redux";
import { SystemStyleObject } from "@styled-system/css";

import { BoxContainer } from "@opr-finance/component-box-container";
import { Font, Text } from "@opr-finance/component-fonts";

import { RecentNewsItem } from "./RecentNewsItem";
import { T_StrapiCmsState } from "../types/general";

export type RecentNewsProps = {
    translation: {
        title: string;
        readMore: string;
    };
    styleConfig?: NewsStyles;
};

type NewsStyles = {
    heading: SystemStyleObject;
    newsWrapper: SystemStyleObject;
    headlines: SystemStyleObject;
    headlinesModal: SystemStyleObject;
    newsDate: SystemStyleObject;
    newsDateModal: SystemStyleObject;
    news: SystemStyleObject;
    newsModal: SystemStyleObject;
    seeMore: SystemStyleObject;
    modalTitle: SystemStyleObject;
    titleText: SystemStyleObject;
    modalOverlay: SystemStyleObject;
    modalContent: SystemStyleObject;
    modalCloseIcon: SystemStyleObject;
};

export function RecentNews(props: RecentNewsProps) {
    const allNews = useSelector((state: any) => state.strapiCms.news);
    const news = allNews && allNews.length >= 2 ? allNews.slice(0, 2) : allNews;
    if (props.styleConfig) {
        return (
            <>
                <Font styleConfig={{ root: props.styleConfig.heading }}>
                    {props.translation.title}
                </Font>
                {news !== null &&
                    news.map((item, index) => (
                        <RecentNewsItem
                            styles={{
                                newsWrapper: props.styleConfig ? props.styleConfig.newsWrapper : {},
                                headlines: props.styleConfig ? props.styleConfig.headlines : {},
                                headlinesModal: props.styleConfig
                                    ? props.styleConfig.headlinesModal
                                    : {},
                                newsDate: props.styleConfig ? props.styleConfig.newsDate : {},
                                newsDateModal: props.styleConfig
                                    ? props.styleConfig.newsDateModal
                                    : {},
                                news: props.styleConfig ? props.styleConfig.news : {},
                                newsModal: props.styleConfig ? props.styleConfig.newsModal : {},
                                seeMore: props.styleConfig ? props.styleConfig.seeMore : {},
                                modalTitle: props.styleConfig ? props.styleConfig.modalTitle : {},
                                titleText: props.styleConfig ? props.styleConfig.titleText : {},
                                modalOverlay: props.styleConfig
                                    ? props.styleConfig.modalOverlay
                                    : {},
                                modalContent: props.styleConfig
                                    ? props.styleConfig.modalContent
                                    : {},
                                modalCloseIcon: props.styleConfig
                                    ? props.styleConfig.modalCloseIcon
                                    : {},
                            }}
                            readMoreLabel={props.translation.readMore}
                            modalTitle={props.translation.title}
                            {...item}
                            key={`item-${index}`}
                        />
                    ))}
            </>
        );
    }

    return (
        <BoxContainer paddingBottom={["16px", "0px"]}>
            <Text variant="bodyTitle" paddingBottom={"8px"}>
                {props.translation.title}
            </Text>
            {news !== null &&
                news.map((item, index) => (
                    <RecentNewsItem
                        modalTitle={props.translation.title}
                        readMoreLabel={props.translation.readMore}
                        {...item}
                        key={`item-${index}`}
                    />
                ))}
        </BoxContainer>
    );
}
