import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { StrapiCMSAction, strapiCmsActions } from "../actions/strapi-cms.actions";
import { T_StrapiCmsState } from "../types/general";

export const initalState: T_StrapiCmsState = {
    messages: null,
    notices: [],
    news: [],
    config: {
        gwUrl: "",
        locale: undefined,
    },
};

export const strapiCmsReducer = createReducer<T_StrapiCmsState, StrapiCMSAction>(initalState)
    .handleAction(strapiCmsActions.fetchTranslationsTrigger, (state, action) => {
        return produce(state, (draftState) => {
            draftState.config.gwUrl = action.payload.gwUrl;
            draftState.config.locale = action.payload.locale;
        });
    })
    .handleAction(strapiCmsActions.fetchTranslationsSuccess, (state, action) => {
        const data: any = action.payload.strapiData;
        return produce(state, (draftState) => {
            draftState.messages = data.translations;
            draftState.news = data.news;
            draftState.notices = data.notice;
        });
    });
