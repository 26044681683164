import { put, call, take, select, race, takeLatest } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";

import { errorActions } from "@opr-finance/feature-error";
import { engagementActions } from "@opr-finance/feature-sme-customer";
import { loginSessionActions } from "@opr-finance/feature-login-session";

import { AppActionConstants, appActions } from "../actions/actions";
import { loginSessionFeatureInitializer } from "./common.saga";
import { AppState, E_ErrorTypes, E_Routes } from "../types/general";
import { getGwProps } from "@opr-finance/utils/src/getGwProps";
import history from "../utils/history";
import { getProfileData } from "../api/getProfileData";
import { T_GatewayProps } from "@opr-finance/utils/src/types/general";

const gw: T_GatewayProps = getGwProps();

export function* watchLoginTrigger() {
    yield takeLatest(AppActionConstants.LOGIN_PAGE_TRIGGER, handleLoginTrigger);
}

export function* handleLoginTrigger(action: ActionType<typeof appActions.loginPageTrigger>) {
    try {
        yield call(loginSessionFeatureInitializer);
        yield put(loginSessionActions.loginSessionComplete());
        yield take(loginSessionActions.loginSessionTokenSuccess);

        const { token, role, ssn } = yield select((state: AppState) => state.session);

        const redirectUrl = sessionStorage.getItem("source");

        if (redirectUrl) {
            const response = yield call(getProfileData);
            sessionStorage.removeItem("source");
            sessionStorage.removeItem("email");

            if (response.status === 401) {
                history.push(`/error/${E_ErrorTypes.FORBIDDEN}`);
                return;
            }

            const { url } = yield response.json();
            window.location.href = url;
            return false;
        }

        yield put(
            engagementActions.engagementInitializer({
                mock: gw.mock,
                gwUrl: gw.fullApiUrl,
                token,
                role,
                reference: ssn,
            })
        );

        yield put(engagementActions.engagementTrigger());

        const [engagementSuccess, engagementError] = yield race([
            take(engagementActions.engagementSuccess),
            take(engagementActions.engagementError),
        ]);
        if (engagementError) history.push(E_Routes.NO_LOAN);

        const engagements = yield select(
            (state: AppState) => state.customer.engagement.engagements
        );

        if (engagements.length === 0) {
            history.push(E_Routes.NO_LOAN);
        } else if (engagements.length > 1) {
            history.push(E_Routes.CHOOSE_ACCOUNT);
        } else {
            yield put(engagementActions.saveSmeIdSuccess(engagements[0].smeId));
            localStorage.setItem("smeId", engagements[0].smeId);
            history.push(E_Routes.FRONT);
        }
        yield put(appActions.loginPageSuccess());
    } catch (e) {
        yield put(errorActions.errorTrigger({ message: "login failed" + e, url: "/error" }));
    }
}
